import { CommonTableRenderers } from '../../../../common/components/table/renderers';
import CommentAttributeRenderer from './CommentAttributeRenderer';
import StatusAttributeRenderer from './StatusAttributeRenderer';
import TaintedAttributeRenderer from './TaintedAttributeRenderer';

class PosterTableRenderersClass extends CommonTableRenderers {
  constructor() {
    super();
    this.registerRenderer('StatusRenderer', StatusAttributeRenderer);
    this.registerRenderer('TaintedRenderer', TaintedAttributeRenderer);
    this.registerRenderer('CommentRenderer', CommentAttributeRenderer);
  }
}

export const PosterTableRenderers = new PosterTableRenderersClass();
