export const posterViewerMixin = {
  props: {
    /**
     * The thin poster request object
     */
    posterRequest: {
      type: Object,
      default: null,
    },
    /**
     * The full poster object with form and values
     */
    poster: {
      type: Object,
      default: null,
    },
    /**
     * If the template fonts are being loaded
     */
    fontsLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * If an error occurred while fetching the fonts
     * for the template
     */
    fontsError: {
      type: Boolean,
      default: false,
    },
    /**
     * The parsed template for the poster type
     */
    currentTemplate: {
      type: Object,
      default: null,
    },
    /**
     * Values of the poster
     */
    values: {
      type: Object,
      default: null,
    },
    /**
     * Types of the poster values
     */
    valuesTypes: {
      type: Object,
      default: null,
    },
  },
};
