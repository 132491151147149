import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { ApiDashboard } from '../../../platform/types/dashboards';
import { AttributeValues } from '../../common/api/attributes';

const DASHBOARD_API_URL = '/api/signages/v1/dashboards';

export default {
  /**
   * Gets the dashboard
   *
   * @param alias - the alias of the dashboard
   * @returns the request result
   */
  getDashboardByAlias(alias: string): Response<ApiDashboard> {
    return Vue.http.get(`${DASHBOARD_API_URL}/${alias}`) as Response<ApiDashboard>;
  },

  /**
   * Runs a query on a dashboard element
   *
   * @param elementAlias - the dashboard element alias
   * @param values - the search values
   * @returns the search result
   */
  runQuery(
    elementAlias: string,
    values: Record<string, AttributeValues.ValueObject>
  ): Response<unknown> {
    return Vue.http.post(
      `${DASHBOARD_API_URL}/${elementAlias}/search`,
      values
    ) as Response<unknown>;
  },
};
