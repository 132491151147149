<template>
  <span
    class="picto-info-caution mdi mdi-alert"
    :class="{ actif: value === true }"
    :title="
      value === true
        ? $t('poster.table.renderers.tainted_attribute.has_diff')
        : $t('poster.table.renderers.tainted_attribute.no_diff')
    "
  />
</template>
<script>
import TableAttributeRendererMixin from '../../../../common/components/table/renderers/TableAttributeRendererMixin';

export default {
  mixins: [TableAttributeRendererMixin],
};
</script>
