import Vue from 'vue';

import services from '../../../core/services';
import { IAuthenticationService } from '../../../core/services/types';
import { FormatsCopiesMap } from '../types/poster';

/**
 * Formats a formats copies object.
 * @param formatsCopiesMap - Object with format Id properties containing copies object (label and copies number)
 * @returns the formatted string
 */
const formatFormatsCopies = (formatsCopiesMap: FormatsCopiesMap): string => {
  const result = [];
  for (const formatId in formatsCopiesMap) {
    const formatCopies = formatsCopiesMap[formatId];
    if (
      formatCopies &&
      Number.isInteger(Number(formatCopies.copies)) &&
      formatCopies.copies > 0 &&
      services.getService<IAuthenticationService>('auth').hasPermission(formatCopies.permission)
    ) {
      result.push(
        `${Number(formatCopies.copies)}(${(
          Vue.filter('piivoTranslate') as (arg: unknown) => string
        )(formatCopies)})`
      );
    }
  }

  return result.join(', ');
};

export const formatters = [
  {
    name: 'FormatsCopies',
    func: formatFormatsCopies,
  },
];
