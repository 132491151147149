export default {
  /**
   * Fetches a resource and creates an object url for it
   *
   * @param url - the url to fetch
   * @param expectedContentType - the expected Content-Type of the resource.
   * If it is not the same, the request will be canceled and the promise will reject
   * @returns an object url to the resource
   */
  createObjectUrlFromRemote(
    url: string,
    expectedContentType: string | null = null
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        // Create request
        const contentRequest = new XMLHttpRequest();
        contentRequest.open('GET', url);
        contentRequest.responseType = 'blob';

        // Apply listeners
        contentRequest.onload = () => {
          if (contentRequest.status === 200) {
            try {
              const response = contentRequest.response || contentRequest.responseText;
              const urlMedia = URL.createObjectURL(response as Blob);
              resolve(urlMedia);
            } catch (error) {
              reject(error);
            }
          } else {
            reject(contentRequest);
          }
        };

        contentRequest.onerror = (error) => {
          reject(error);
        };

        contentRequest.onabort = (error) => {
          reject(error);
        };

        if (expectedContentType) {
          contentRequest.onreadystatechange = () => {
            if (contentRequest.readyState === contentRequest.HEADERS_RECEIVED) {
              const contentType = contentRequest.getResponseHeader('Content-Type');
              if (contentType !== expectedContentType) {
                contentRequest.abort();
              }
            }
          };
        }

        // Send request
        contentRequest.send();
      } catch (error) {
        reject(error);
      }
    });
  },
};
