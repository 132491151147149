import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { AttributeValues } from '../../common/api/attributes';
import { TemplateValues } from './types';

const TEMPLATES_API_URL = '/api/signages/templates';

export default {
  /**
   * Get template content.
   *
   * @param templateId - The tempplate identifier.
   */
  async getTemplateContent(templateId: string) {
    return Vue.http.get(`${TEMPLATES_API_URL}/${templateId}/content`);
  },

  /**
   * Get template values.
   *
   * @param templateId - The template identifier.
   * @param bodyParameters - The optional body parameters.
   */
  async getTemplateValues(
    templateId: string,
    bodyParameters: Map<string, unknown>
  ): Response<TemplateValues.BindingValues> {
    return Vue.http.post(
      `${TEMPLATES_API_URL}/${templateId}/values`,
      Object.fromEntries(bodyParameters || [])
    ) as Response<TemplateValues.BindingValues>;
  },

  /**
   * Search template values.
   *
   * @param templateId - The template identifier.
   * @param searchSourceId - The search source Id.
   * @param bodyParameters - The optional body parameters.
   */
  async searchTemplateValues(
    templateId: string,
    searchSourceId: string,
    bodyParameters: Map<string, unknown>,
    paging?: { index: number; size: number },
    sorts?: [string, string][]
  ): Response<TemplateValues.SearchResult> {
    const pagingParam = paging
      ? JSON.stringify({ size: paging.size, index: paging.index })
      : undefined;

    let sortingParamValue =
      sorts
        ?.filter(([sortKey, sortDirection]) => sortDirection !== 'none')
        .map(([sortKey, sortDirection]) => `{"${sortKey}":"${sortDirection}"}`)
        .join(',') ?? '';
    sortingParamValue = sortingParamValue ? `[${sortingParamValue}]` : '';

    return Vue.http.post(
      `${TEMPLATES_API_URL}/${templateId}/values/${searchSourceId}/search`,
      Object.fromEntries(bodyParameters || []),
      {
        params: {
          paging: pagingParam,
          sorting: sortingParamValue,
        },
      }
    ) as Response<TemplateValues.SearchResult>;
  },

  /**
   * Get values for a sourceId and parameters.
   *
   * @param templateId - The template identifier.
   * @param searchSourceId - The search source Id.
   * @param bodyParameters - The optional body parameters.
   * @deprecated use attributesApi.getPossibleValues
   */
  async getPossibleValues<OptionValue extends AttributeValues.AttributeOptionValue>(
    templateId: string,
    searchSourceId: string,
    bodyParameters: Map<string, unknown>
  ): Response<OptionValue[]> {
    return Vue.http.post(
      `${TEMPLATES_API_URL}/${templateId}/values/${searchSourceId}`,
      Object.fromEntries(bodyParameters || [])
    ) as Response<OptionValue[]>;
  },
};
