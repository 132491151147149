/**
 * Fake attributes for batch creation to NOT include when creating a poster
 */
export const BATCH_CREATION_IGNORE_ATTRIBUTE_NAMES = ['piivo_type', 'piivo_formats'];

/**
 * Batch creation url query key names
 */
export const UrlQueryKeys = {
  /**
   * The import mode alias
   */
  IMPORT_MODE: 'import_mode',
  /**
   * The search execute mode
   */
  EXECUTE_SEARCH: 'execute_search',
};

/**
 * Auto search execution
 */
export const EXECUTE_SEARCH_AUTO = '"auto"';
