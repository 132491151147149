import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { Label } from '../../../core/types/i18n';

export namespace Actions {
  export interface Form {
    href: string;
    rel: string;
    method: string;
  }

  export interface Action {
    itemId: string;
    alias: string;
    label: Label;
    form: Form | null;
    createdDate: Date;
    updatesDate: Date;
  }
}

const API_URL_BASE = '/api/signages/v1/actions';

export default {
  /**
   *
   * @param actionAlias - alias of the action
   * @returns the action's configuration
   */
  getActionByAlias(actionAlias: string): Response<Actions.Action> {
    return Vue.http.get(`${API_URL_BASE}/${actionAlias}`) as Response<Actions.Action>;
  },
};
