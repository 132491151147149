<template>
  <div
    ref="editor"
    class="format-content"
    tabindex="-1"
    @keydown.enter.stop="onBlur(item[value])"
    @focus="focusEditor"
    @blur="blurEditor"
  >
    <pui-input
      v-for="(formatCopies, formatId) in item[value]"
      ref="copiesEditor"
      :key="formatId"
      v-model="formatCopies.copies"
      type="number"
      :min="MIN_COPIES"
      :max="MAX_COPIES"
      :step="1"
      :label="formatCopies.label"
      @focus="focusEditor"
      @blur="blurEditor"
    >
    </pui-input>
  </div>
</template>

<script>
import Vue from 'vue';

import TableAttributeEditorMixin from '../../../../common/components/table/editors/TableAttributeEditorMixin';
import { MAX_COPIES, MIN_COPIES } from '../../../constants';

export default {
  components: {},
  mixins: [TableAttributeEditorMixin],
  data() {
    return {
      MIN_COPIES,
      MAX_COPIES,
      editorFocused: false,
    };
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    /**
     * Editor focus.
     */
    focus() {
      if (this.$refs.copiesEditor && this.$refs.copiesEditor[0]) {
        this.$refs.copiesEditor[0].focus();
      }
    },
    /**
     * Focus editor or editor component (keep focus on editor).
     */
    focusEditor() {
      this.editorFocused = true;
    },
    /**
     * Blur on editor or editor components (verify if editor is blur or not).
     */
    blurEditor() {
      this.editorFocused = false;

      // Verify if editor is still focused
      setTimeout(() => {
        if (this.editorFocused === false) {
          this.onBlur(this.item[this.value]);
        }
      }, 10);
    },
  },
};
</script>
