<template>
  <pui-flex :class="{ 'search-summary search-summary__root': true, minimized: minimized }">
    <pui-flex class="search-summary-panel" direction="column" flex="1">
      <div class="summary-header">
        <h3 class="summary-title">
          {{ searchDefinition.summary.title | piivoTranslateLabel }}
        </h3>
        <div class="summary-actionbar">
          <pui-flex class="summary-buttons">
            <pui-button
              id="quick-import-modal-selection-delete"
              :disabled="$refs.table == null || $refs.table.hasSelection === false"
              outline
              picto="mdi-trash-can"
              variant="primary"
              class="summary-button"
              @click="deleteFromSearchSelection"
            >
              {{ $t('poster.creation.remove_selection') }}
            </pui-button>
          </pui-flex>
          <p v-if="value && value.length > 0" class="element-count">
            {{
              $tc('poster.creation.search.element_count', value.length, {
                count: value.length,
              })
            }}
            <span v-if="$refs.table != null && $refs.table.hasSelection">
              {{
                $tc(
                  'poster.creation.search.element_selected_count',
                  $refs.table.getSelectedItems().length
                )
              }}
            </span>
          </p>
        </div>
      </div>
      <pui-table
        ref="table"
        :columns="searchDefinition.summary.columns"
        :allowSelection="true"
        :items="value"
        :isCellSelectable="false"
        :tableEditors="PosterTableEditors"
        :tableRenderers="PosterTableRenderers"
        :getItemId="getSearchResultId"
        @cellItemValueChanged="onSummaryCellValueChanged"
      />
    </pui-flex>
  </pui-flex>
</template>

<script>
import Vue from 'vue';

import { PosterTableEditors } from '../table/editors';
import { PosterTableRenderers } from '../table/renderers';

export default {
  name: 'PosterSearchSummary',
  props: {
    /**
     * Search definition object (search modes, summary)
     */
    searchDefinition: {
      type: Object,
      required: true,
    },
    /**
     * If the summary is minimized
     */
    minimized: {
      type: Boolean,
      required: true,
    },
    /**
     * Array of search items
     */
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Constants
      PosterTableEditors,
      PosterTableRenderers,
    };
  },
  methods: {
    // FILTERS
    piivoTranslateLabel(value) {
      return Vue.filter('piivoTranslateLabel')(value);
    },
    /**
     * @param {object} item - search result item
     * @returns {number} unique id for the item
     */
    getSearchResultId(item) {
      return item._id;
    },
    /**
     * Launch on cell change for a signage item in the summary table.
     * @param {Object} item - signage item modified
     * @param {String} property - property name
     * @param {Object} value - new value
     */
    onSummaryCellValueChanged(item, property, value) {
      item[property] = value;
    },
    /**
     * Delete selected item from search selection
     */
    deleteFromSearchSelection() {
      const updatedSelection = [...this.value];
      if (this.$refs.table != null) {
        for (const selectionItem of this.$refs.table.getSelectedItems()) {
          const indexToDelete = updatedSelection.indexOf(selectionItem);
          updatedSelection.splice(indexToDelete, 1);
          this.$refs.table.resetSelection();
        }
        this.$emit('input', updatedSelection);
      }
    },
  },
};
</script>
