import { Scopes } from '../../common/constants/binaries';

const SIGNAGES_API_URL = '/signages/v1/signages';

export default {
  /**
   *
   * @param itemId - poster id
   * @param scope - scope of the binary
   * @returns the url to the binary
   */
  getBinaryUrl(itemId: string, scope: Scopes): string {
    return `${SIGNAGES_API_URL}/${itemId}/${scope}`;
  },
};
