<template>
  <pui-card class="gallery-thumbnail gallery-thumbnail__root">
    <template #default>
      <pui-image-viewer
        class="gallery-thumbnail__image-viewer"
        :src="thumbnailURL"
        :mode="Mode.SRC"
        :lazy="lazy"
        @load="$emit('loaded')"
      >
        <template #placeholder>
          <pui-flex
            class="gallery-thumbnail__loader-container"
            justifyContent="center"
            alignItems="center"
          >
            <pui-ball-loader></pui-ball-loader>
          </pui-flex>
        </template>

        <template #error>
          <pui-flex
            v-if="generationInProgress"
            class="gallery-thumbnail__loader-container"
            justifyContent="center"
            alignItems="center"
          >
            <pui-ball-loader></pui-ball-loader>
          </pui-flex>
          <img
            v-else
            class="gallery-thumbnail__error"
            :src="DEFAULT_THUMBNAIL_URL"
            @load="$emit('loaded')"
          />
        </template>
      </pui-image-viewer>
    </template>
  </pui-card>
</template>
<script>
import { Mode } from 'piivo-ui/src/components/image/ImageViewer';

import { Scopes } from '../../common/constants/binaries';
import signagesApi from '../api/signages';
import { PosterGenerationStatus } from '../constants';
import DEFAULT_THUMBNAIL_URL from '../static/img/default-thumbnail.png';

export default {
  name: 'PosterGalleryThumbnail',
  props: {
    /**
     * The poster to display the thumbnail for
     */
    poster: {
      type: Object,
      required: true,
    },
    /**
     * If the thumbnail should be lazily loaded
     */
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Mode,
      DEFAULT_THUMBNAIL_URL,
      PosterGenerationStatus,
    };
  },
  computed: {
    /**
     * @returns {boolean} if the poster's generation is in progress
     */
    generationInProgress() {
      return this.poster.generationStatus === PosterGenerationStatus.InProgress;
    },
    /**
     * @returns {boolean} if the poster was fully generated
     */
    hasGenerationSuccess() {
      return (
        // If status is null, assume the poster was created before the property was created :
        // the thumbnail should still exist
        !this.poster.generationStatus ||
        this.poster.generationStatus === PosterGenerationStatus.Generated
      );
    },
    /**
     * @returns {string} the poster thumbnail url
     */
    thumbnailURL() {
      return this.hasGenerationSuccess
        ? signagesApi.getBinaryUrl(this.poster.itemId, Scopes.Thumbnails)
        : '';
    },
  },
};
</script>
