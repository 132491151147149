import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { RootImportMode } from './../types/import';

/**
 * Import modes API.
 */
const API_URL_BASE = '/api/signages/importmodes';

export default {
  /**
   * Get import configuration from a type id.
   *
   * @param id - Type Identifier (Guid)
   * @returns the corresponding import configuration with all import modes.
   */
  async getImportConfigurationByTypeId(typeId: string) {
    return Vue.http.get(`${API_URL_BASE}/${typeId}`) as Response<RootImportMode>;
  },
};
