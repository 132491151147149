/**
 * Actions sent from the cart state service to the cart sync worker
 */
export enum CartSyncServiceActions {
  /**
   * Requests the worker to reload the cart
   */
  ReloadCart = 'reload_cart',

  /**
   * Sends the config from the service to the worker
   */
  SendConfig = 'send_config',

  /**
   * Cart has been modified by the user
   */
  CartModified = 'cart_modified',

  /**
   * Sends the choice of whether to accept the incoming
   * cart state to the worker
   */
  ConfirmAcceptIncomingStateAck = 'confirm_accept_incoming_state_ack',

  /**
   * Requests worker to start sync polling
   */
  StartPolling = 'start_polling',
}

/**
 * Actions send from the cart state sync worker to the cart state service
 */
export enum CartSyncWorkerActions {
  /**
   * Worker is waiting for initialization
   */
  WaitingForInit = 'waiting_for_init',

  /**
   * Worker is ready to handle requests
   */
  WorkerReady = 'worker_ready',

  /**
   * Cart has been updated in db by the worker
   */
  ReloadCartAck = 'reload_cart_ack',
  /**
   * Cart date has been updated in db by the worker
   */
  ReloadCartDateAck = 'reload_cart_date_ack',

  /**
   * Requests the user to confirm whether to accept
   * the incoming cart state
   */
  ConfirmAcceptIncomingState = 'confirm_accept_incoming_state',

  /**
   * Cart sync is in progress inside the worker
   */
  SyncInProgress = 'sync_in_progress',
  /**
   * Error syncing the cart
   */
  SyncErr = 'sync_err',
  /**
   * The cart count has reached the sync limit
   */
  SyncLimitReached = 'sync_limit_reached',
}

/***
 * The limit of posters that can be synchronized
 */
export const CART_SYNC_LIMIT = 1_000;

/**
 * Actions send from the send posters service to the worker
 */
export enum SendPostersServiceAction {
  /**
   * Sends the config from the service to the worker
   */
  SendConfig = 'send_config',

  CreatePosters = 'create_posters',
}

/**
 * Actions send from the send posters worker to the service
 */
export enum SendPostersWorkerAction {
  /**
   * Worker is ready to handle requests
   */
  WorkerReady = 'worker_ready',

  /**
   * Pure creation progress, not the action workflow progress
   */
  CreationProgress = 'creation_progress',

  /**
   * Send is done
   */
  SendDone = 'send_done',
}
