<template>
  <poster-dashboard-widget
    class="poster-dashboard-welcome-widget poster-dashboard-welcome-widget__title"
    v-bind="$attrs"
  >
    <h2 class="poster-dashboard-welcome-widget__title">
      {{ $t('poster.dashboard.welcome_user', { name: user.displayName }) }}
    </h2>
  </poster-dashboard-widget>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PosterDashboardWelcomeWidget',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
};
</script>
