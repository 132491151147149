/**
 * Suffix to add to an incoming action to use as the reply
 */
export const ACK_SUFFIX = '_ack';

/**
 * @param worker - the worker on which to listen
 * @param action - the action to wait for
 * @returns the first event with the action
 */
export async function waitOnce<D = unknown>(
  worker: Worker,
  action: string
): Promise<MessageEvent<D>> {
  return new Promise((resolve) => {
    const listener = (e: MessageEvent) => {
      if (e.data.action === action) {
        worker.removeEventListener('message', listener);
        resolve(e);
      }
    };
    worker.addEventListener('message', listener);
  });
}

/**
 * @param worker - the worker on which to listen
 * @param action - the action to wait for
 * @param payload the payload to send
 * @returns the first event that replies with the action acknowledgment code
 */
export async function waitUntilAck<P = unknown>(
  worker: Worker,
  action: string,
  payload: P | null = null
): Promise<MessageEvent> {
  const ackPromise = waitOnce(worker, `${action}${ACK_SUFFIX}`);
  worker.postMessage({ action, payload });
  return ackPromise;
}
