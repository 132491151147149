<template>
  <div v-scrollable.y class="poster-search-form poster-search-form__root">
    <h3>
      {{
        searchDefinition.title
          ? piivoTranslateLabel(searchDefinition.title)
          : $t('poster.search.form.title')
      }}
    </h3>
    <p
      class="poster-search-form__search-description"
      v-html="piivoTranslateLabel(searchDefinition.description)"
    />
    <pui-attribute-group-panel
      v-for="group in searchDefinition.searchForm.attributeGroups"
      :key="group.itemId"
      :group="group"
    >
      <template v-if="group.attributes && searchValues">
        <pui-attribute-panel
          v-for="attribute in group.attributes"
          :ref="getAttributePanelRefName(attribute.alias)"
          :key="attribute.itemId"
          :attribute="attribute"
          :value="searchValues[attribute.alias]"
          :loadOptionsFunction="loadOptionsFunction"
          :disabled="disabled"
          class="poster-search-form__attribute-panel"
          @valueInput="$emit('attributeValueInput', attribute, $event)"
          @valueChanged="$emit('attributeValueChange', attribute, $event)"
          @keyup.enter="$emit('attributeKeyup', attribute)"
        />
      </template>
    </pui-attribute-group-panel>

    <pui-flex v-if="$scopedSlots.actions" class="poster-search-form__actions" justifyContent="end">
      <slot name="actions"></slot>
    </pui-flex>
  </div>
</template>

<script>
import Vue from 'vue';

import { AttributeTypes } from '../../../common/constants';
import { updateOn } from '../../../common/helpers/formsHelper';

export default {
  name: 'PosterSearchForm',
  props: {
    /**
     * Search definition object
     */
    searchDefinition: {
      type: Object,
      required: true,
    },
    /**
     * Object map of search values
     */
    searchValues: {
      type: Object,
      required: true,
    },
    /**
     * Load function for paginated Links attribute (async loading) options.
     *
     * @type {(attribute: object, options: { context?: object }) => Promise<object[]|null>}
     */
    loadOptionsFunction: {
      type: Function,
      required: true,
    },
    /**
     * Disables the form
     */
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      focusedAttributeAlias: null,
    };
  },
  methods: {
    // FILTERS
    piivoTranslateLabel(value) {
      return Vue.filter('piivoTranslateLabel')(value);
    },
    /**
     * @param {string} alias - attribute alias
     * @returns {string} value to use as ref name for an attribute's alias
     */
    getAttributePanelRefName(alias) {
      return `attribute_${alias}`;
    },
    /**
     * Checks the type and visibility of the attribute panel
     *
     * @param {*[]} attributePanel - the vue component array (since the ref is on v-for)
     * @param {object} attribute - the attribute object
     * @returns {boolean} if this attribute panel can be focused
     */
    canFocusAttributePanel(attributePanel, attribute) {
      return (
        attributePanel != null &&
        attributePanel[0] != null &&
        attribute.type !== AttributeTypes.LINKS &&
        attributePanel[0].isVisible
      );
    },
    /**
     * Focuses the first visible attribute in the form
     */
    focusFirstAttribute() {
      let focusDone = false;
      for (const group of this.searchDefinition.searchForm.attributeGroups) {
        if (focusDone === false) {
          for (const attribute of group.attributes) {
            // Do not focus invisible or Links type attribute
            const attributePanel = this.$refs[this.getAttributePanelRefName(attribute.alias)];
            if (this.canFocusAttributePanel(attributePanel, attribute)) {
              attributePanel[0].focus();
              focusDone = true;
              break;
            }
          }
        } else {
          break;
        }
      }
    },
    /**
     * Saves the focused attribute
     */
    saveFocusedAttribute() {
      const allAttributes = this.searchDefinition.searchForm.attributeGroups.flatMap(
        (group) => group.attributes
      );
      let attributeIndex = 0;
      while (attributeIndex < allAttributes.length && !this.focusedAttributeAlias) {
        const attribute = allAttributes[attributeIndex];
        const attributePanel = this.$refs[this.getAttributePanelRefName(attribute.alias)];
        if (
          this.canFocusAttributePanel(attributePanel, attribute) &&
          document.activeElement === attributePanel[0].getInput().$refs.input
        ) {
          this.focusedAttributeAlias = attribute.alias;
        }
        attributeIndex++;
      }
    },
    /**
     * Restores focus to the attribute saved in `saveFocusedAttribute`
     */
    restoreFocusedAttribute() {
      if (!this.focusedAttributeAlias) {
        return;
      }

      const attributePanel = this.$refs[this.getAttributePanelRefName(this.focusedAttributeAlias)];
      const attribute = attributePanel !== null ? attributePanel[0].attribute : null;
      if (this.canFocusAttributePanel(attributePanel, attribute)) {
        attributePanel[0].focus();
      }
    },
    /**
     * Launches the updateOn mechanism for the attribute
     *
     * @param {object} attribute - attribute for whom to launch updateOn
     */
    doUpdateOn(attribute) {
      const actionCb = (cbAttr) => {
        if (cbAttr.type === AttributeTypes.LINKS) {
          this.$nextTick(() => {
            const attributePanel = this.$refs[this.getAttributePanelRefName(cbAttr.alias)];
            if (attributePanel != null && attributePanel[0] != null) {
              attributePanel[0].loadOptions().catch(() => null);
            }
          });
        }
      };

      // Launch updateOn mechanism for current attribute alias
      updateOn(this.searchDefinition.searchForm, attribute, actionCb);
    },
  },
};
</script>
