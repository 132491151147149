import { getAppDashboards } from '../../../platform/helpers/dashboardHelpers';
import { IDashboardsService } from '../../../platform/services/types';
import { DashboardInfo } from '../../../platform/types/dashboards';
import { AttributeValues } from '../../common/api/attributes';
import dashboardsApi from '../api/dashboards';
import { POSTER_APP_NAME } from './../constants';

export class PosterDashboardsService implements IDashboardsService {
  /**
   * @returns the poster app's dashboards
   */
  getDashboards(): DashboardInfo[] | undefined {
    return getAppDashboards(POSTER_APP_NAME);
  }

  /** @inheritdoc */
  async getDashboardByAlias(alias: string) {
    const res = await dashboardsApi.getDashboardByAlias(alias);
    return res.body;
  }

  /** @inheritdoc */
  async runQuery(
    elementAlias: string,
    values: Record<string, AttributeValues.ValueObject>
  ): Promise<unknown> {
    const res = await dashboardsApi.runQuery(elementAlias, values);
    return res.body;
  }
}
