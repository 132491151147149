export interface OpenOptions {
  /**
   * Called if the "upgradeneeded" event is emitted
   *
   * @param db - the newly created database
   */
  onUpgrade?: (db: IDBDatabase) => void;
}

/**
 * Creates an IndexedDB database
 *
 * @param name - name of the database
 * @param version - version of the database
 * @param opts - creation options
 * @returns resolves or rejects on database creation success/error
 */
export async function openDb(
  name: string,
  version?: number,
  opts?: OpenOptions
): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const req = indexedDB.open(name, version);

    req.onupgradeneeded = () => {
      const db = req.result;

      opts?.onUpgrade?.(db);
    };

    req.onerror = () => reject(req.error);
    req.onsuccess = () => resolve(req.result);
  });
}
