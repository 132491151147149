<template>
  <pui-dialog
    ref="dialog"
    :title="$t('poster.creation.resolve_cart_conflict.title')"
    :showCloseButton="false"
    class="resolve-cart-conflict-dialog resolve-cart-conflict-dialog__root"
    transition="slide-up"
    width="40%"
  >
    <div slot="dialog-action" class="dialog-action">
      <pui-button outline variant="secondary" @click="onAcceptIncoming">
        {{ $t('poster.creation.resolve_cart_conflict.cancel') }}
      </pui-button>
      <pui-button variant="primary" @click="onAcceptCurrent">
        {{ $t('poster.creation.resolve_cart_conflict.confirm') }}
      </pui-button>
    </div>

    <pui-error picto="mdi-alert" class="resolve-cart-conflict-dialog__message-root">
      <i18n class="msg" path="poster.creation.resolve_cart_conflict.message.primary"></i18n>
      <i18n class="msg" path="poster.creation.resolve_cart_conflict.message.secondary"></i18n
    ></pui-error>
  </pui-dialog>
</template>

<script>
import { getPosterService } from '../../services';
export default {
  name: 'PosterResolveCartConflictDialog',
  methods: {
    /**
     * Close dialog
     */
    close() {
      if (this.$refs.dialog) {
        this.$refs.dialog.close();
      }
    },
    /**
     * Opens the dialog
     */
    open() {
      if (this.$refs.dialog) {
        this.$refs.dialog.open();
      }
    },
    /**
     * Triggered when the user accepts the incoming cart
     */
    onAcceptIncoming() {
      getPosterService('cartState').acceptIncomingCart();
      this.close();
    },
    /**
     * Triggered when the user accepts the current cart
     */
    onAcceptCurrent() {
      getPosterService('cartState').acceptCurrentCart();
      this.close();
    },
  },
};
</script>
