<template>
  <span
    class="picto-info-comment mdi mdi-comment-processing"
    :class="{ actif: value }"
    :title="value || $t('poster.table.renderers.comment_attribute.no_comment')"
  />
</template>
<script>
import TableAttributeRendererMixin from '../../../../common/components/table/renderers/TableAttributeRendererMixin';

export default {
  mixins: [TableAttributeRendererMixin],
};
</script>
