<template>
  <pui-dialog
    ref="dialog"
    :title="$t('poster.creation.send_limited_count_confirmation.title')"
    :showCloseButton="false"
    class="send-limited-count-confirmation-dialog send-limited-count-confirmation-dialog__root"
    transition="slide-up"
    width="40%"
  >
    <div slot="dialog-action" class="dialog-action">
      <pui-button outline variant="secondary" @click="onCancel(close)">
        {{ $t('poster.creation.send_limited_count_confirmation.cancel') }}
      </pui-button>
      <pui-button variant="primary" @click="onConfirm(close)">
        {{ $t('poster.creation.send_limited_count_confirmation.confirm') }}
      </pui-button>
    </div>

    <pui-error :picto="'mdi-alert'" class="send-limited-count-confirmation-dialog__message-root">
      <template>
        <i18n class="msg" path="poster.creation.send_limited_count_confirmation.message.primary">
          <template #sendCount>{{ nbSignageItems }}</template>
          <template #sendAction>{{
            sendAction
              ? $t(
                  `poster.creation.send_limited_count_confirmation.message.primary_action.${sendAction}`
                )
              : ''
          }}</template>
          <template #limit>{{ posterLimit }}</template>
        </i18n>

        <i18n class="msg" path="poster.creation.send_limited_count_confirmation.message.secondary">
          <template #confirm>
            <i18n
              class="bold-txt"
              path="poster.creation.send_limited_count_confirmation.confirm"
            ></i18n>
          </template>
          <template #sendAction>{{
            sendAction
              ? $t(
                  `poster.creation.send_limited_count_confirmation.message.secondary_action.${sendAction}`
                )
              : ''
          }}</template>
          <template #firstN>
            <i18n
              class="bold-txt"
              path="poster.creation.send_limited_count_confirmation.message.firstN"
            >
              <template #limit>{{ posterLimit }}</template>
            </i18n>
          </template>
          <template #sendCount>
            {{ nbSignageItems }}
          </template>
          <template #remainder>
            {{ nbSignageItems - posterLimit }}
          </template>
          <template #page>
            {{ page ? $t(`poster.creation.send_limited_count_confirmation.page.${page}`) : '' }}
          </template>
        </i18n>

        <i18n class="msg" path="poster.creation.send_limited_count_confirmation.message.tertiary">
          <template #page>
            {{ page ? $t(`poster.creation.send_limited_count_confirmation.page.${page}`) : '' }}
          </template>
        </i18n>
      </template>
    </pui-error>
  </pui-dialog>
</template>

<script>
import { CreationActions } from '../../constants';

export default {
  name: 'PosterSendLimitedCountConfirmationDialog',
  props: {
    /**
     * The number of selected posters
     */
    nbSignageItems: {
      type: Number,
      required: true,
    },
    /**
     * The number of invalid posters that are selected
     */
    posterLimit: {
      type: Number,
      required: true,
    },
    /**
     * The page we are sending from
     * @type "creation" | "demands"
     */
    page: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      onCancel: () => {},
      onConfirm: () => {},
      sendAction: null,
      CreationActions,
    };
  },
  methods: {
    /**
     * Close dialog
     */
    close() {
      if (this.$refs.dialog) {
        this.$refs.dialog.close();
      }
      this.onCancel = () => {};
      this.onConfirm = () => {};
      this.sendAction = null;
    },
    /**
     * Opens the dialog
     *
     * @param {object} callbacks - object of user callbacks
     * @param {Function} callbacks.onCancel - called when the user wants to continue editing the poster
     * @param {Function} callbacks.onConfirm - called when the user wants
     * to continue sending the posters
     */
    open({ onCancel, onConfirm, sendAction } = {}) {
      this.onCancel = onCancel;
      this.onConfirm = onConfirm;
      this.sendAction = sendAction;

      if (this.$refs.dialog) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>
