import { waitOnce } from '../../../core/helpers/workerHelpers';
import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { ILanguagesManagerService } from '../../../core/services/types';
import store from '../../../core/store';
import { Label } from '../../../core/types/i18n';
import {
  IAlertsService,
  IAnalyticsService,
  INotificationsService,
  PiivoApplicationLifecycleService,
} from '../../../platform/services/types';
import { AttributeValues } from '../../common/api/attributes';
import { IActionsService } from '../../common/services/types';
import logs from '../api/logs';
import { PosterLog } from '../api/logsTypes';
import sendPosterNotification from '../components/creation/SendPosterNotification.vue';
import {
  CreationActions,
  DEFAULT_SIGNAGE_SOURCE,
  PosterCreationStates,
  Statutes,
} from '../constants';
import Tracking from '../constants/tracking';
import { GetSendActionAlias } from '../helpers/actionDefinitionHelpers';
import { getSendBatchSize } from '../helpers/posterHelper';
import { CartPoster, EditPoster } from '../types/poster';
import { SendPostersServiceAction, SendPostersWorkerAction } from '../workers/shared';
import { getPosterService } from './index';
import {
  ConvertDetails,
  IDownloadPostersService,
  ISendPostersService,
  ISignagesService,
  ISignagesSynchronization,
} from './types';

/**
 * Action callback for worker messages
 */
type ActionCallback = (err: unknown | null, posterIds: string[] | null) => void;

export class SendPostersService implements PiivoApplicationLifecycleService, ISendPostersService {
  /**
   * The send posters worker
   */
  worker: Worker | null = null;
  /**
   * Did initialize service
   */
  didInit = false;
  /**
   * Send actions
   */
  actions = new Map<string, { callback: ActionCallback; onProgress: (progress: number) => void }>();

  /**
   * @inheritdoc
   */
  public async onApplicationMount(): Promise<void> {
    if (this.didInit) {
      // Prevent duplicate setup from external sources
      return;
    }

    this.worker = new Worker(new URL('../workers/sendPosters.worker', import.meta.url));

    // Setup listeners
    this.worker.addEventListener('message', this.onWorkerMessage);

    // Init worker
    const workerReadyPromise = waitOnce(this.worker, SendPostersWorkerAction.WorkerReady);

    // Init config
    this.worker?.postMessage({
      action: SendPostersServiceAction.SendConfig,
      payload: {
        accessToken: store.state.auth.accessToken,
      },
    });

    // Wait for worker init finish
    await workerReadyPromise;
  }

  /**
   * @inheritdoc
   */
  public onBeforeApplicationDestroyed(): void {
    this.worker?.removeEventListener('message', this.onWorkerMessage);
    this.worker?.terminate();
    this.worker = null;

    this.didInit = false;
  }

  /**
   * @inheritdoc
   */
  async createPosters(
    cartPosters: CartPoster[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<string> {
    return this.createPostersWithStatus(
      cartPosters,
      parameters,
      CreationActions.CREATE,
      Statutes.TO_BE_PRINTED
    );
  }

  /**
   * @inheritdoc
   */
  public async createAndDownload(
    cartPosters: CartPoster[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<string> {
    const converter = await getPosterService<IDownloadPostersService>(
      'downloadPosters'
    ).createConverter(cartPosters.length, Tracking.QUICK_DOWNLOAD, true, {});

    const sendPostersPromise = this.sendPosters(
      cartPosters,
      Statutes.TO_BE_PRINTED,
      parameters,
      CreationActions.DOWNLOAD,
      (progress) => {
        converter.setCreationProgress(progress);
      }
      // eslint-disable-next-line promise/prefer-await-to-then
    ).then((res) => res.actionId);

    // Do not await : notifications/alerts... should happen in background
    let ignoreErr = false;
    sendPostersPromise
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err) => {
        ignoreErr = true;
        // Make sure download notification shows send error
        converter.onStartError(err);
        throw err;
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((actionId) => converter.followPdfConversion(actionId))
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err) => {
        if (!ignoreErr) {
          // Only log error from converter itself not the send posters promise
          console.error(err);
        }
      });

    // Do not await the returned promise, the function should resolve as soon as the send
    // promise is created
    return sendPostersPromise;
  }

  /**
   * @inheritdoc
   */
  async createAndPrintPosters(
    cartPosters: CartPoster[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<string> {
    return this.createPostersWithStatus(
      cartPosters,
      parameters,
      CreationActions.PRINT_AUTO,
      Statutes.WAITING_TO_BE_PRINTED
    );
  }

  /**
   * Builds poster objects for creation
   *
   * @param cartPosters - the cart posters
   * @param status - the status to set in the built poster objects
   * @returns the built posters
   */
  private async createCreationPosterDtos(
    cartPosters: CartPoster[],
    status: string
  ): Promise<PosterLog.CreationLog[]> {
    // Build up posters and group them by format (corresponds paper type)
    // so that formats can be sent sequentially
    // => all posters of a format can be handled by the backend without
    // needing to wait for all posters to be sent
    const builtPosterMap: Record<string, PosterLog.CreationLog[]> = {};

    const currentLanguage = services
      .getService<ILanguagesManagerService>('languages')
      .getSelectedLocale();

    for (const poster of cartPosters) {
      for (const formatId in poster.formatsCopiesMap) {
        const areFormatCopiesValid = getPosterService<ISignagesService>(
          'signages'
        ).checkFormatCopies(poster.formatsCopiesMap, formatId);
        if (!areFormatCopiesValid) {
          continue;
        }

        // Build poster
        const builtPoster = await getPosterService<ISignagesService>('signages').buildPosterDto(
          poster,
          formatId,
          status,
          currentLanguage
        );
        builtPosterMap[formatId] = builtPosterMap[formatId] || [];
        builtPosterMap[formatId].push(builtPoster);
      }
    }

    const builtPosters = Object.values(builtPosterMap).flatMap(
      (postersForFormat) => postersForFormat
    );

    return builtPosters;
  }

  /**
   * Send the posters and runs the associated workflow
   *
   * @param cartPosters - the cart posters
   * @param status - the status to create the posters with
   * @param parameters - send parameters
   * @param sendAction - the send action
   * @param onProgress - creation progress callback
   * @returns a promise that resolves when the workflow is started
   */
  private async sendPosters(
    cartPosters: CartPoster[],
    status: string,
    parameters: PosterLog.CreationParameters | null,
    sendAction: CreationActions,
    onProgress: (progress: number) => void
  ): Promise<{ posterIds: string[]; actionId: string }> {
    onProgress(0);

    const builtPosters = await this.createCreationPosterDtos(cartPosters, status);

    const action = await services.getService<IActionsService>('actions').createAction();

    const actionAlias = GetSendActionAlias(sendAction);
    const chunkSize = getSendBatchSize();

    const res = await new Promise<{ posterIds: string[]; actionId: string }>((resolve, reject) => {
      const callback: ActionCallback = (err: unknown | null, posterIds: string[] | null) => {
        if (err) {
          reject(err);
        }

        resolve({ posterIds: posterIds!, actionId: action.actionId });

        this.actions.delete(action.actionId);
      };

      this.actions.set(action.actionId, { callback, onProgress });

      this.worker?.postMessage({
        action: SendPostersServiceAction.CreatePosters,
        payload: {
          posters: builtPosters,
          parameters,
          chunkSize,
          actionId: action.actionId,
          actionAlias,
        },
      });
    });

    // Start sync delay so new posters appear in demands page
    getPosterService<ISignagesSynchronization>('signagesSync').startSynchronizationDelay();

    return res;
  }

  /**
   * Creates the posters and handles create/print auto notification
   *
   * @param cartPosters - the cart posters
   * @param parameters - the download parameters
   * @param sendAction - the send action
   * @param status - the status to create the posters with
   * @returns the action id
   */
  async createPostersWithStatus(
    cartPosters: CartPoster[],
    parameters: PosterLog.CreationParameters | null,
    sendAction: CreationActions,
    status: string
  ): Promise<string> {
    services.getService<IAnalyticsService>('analytics').trackEvent(
      Tracking.QUICK_SEND,
      {},
      {
        signagesNumber: cartPosters.length,
      }
    );

    const notificationId =
      (await services
        .getService<INotificationsService>('notifications')
        .createNotification(sendPosterNotification, {
          state: PosterCreationStates.IN_PROGRESS,
          action: sendAction,
          count: cartPosters.length,
          progress: -1,
        })) || -1;

    const sendPostersPromise = this.sendPosters(
      cartPosters,
      status,
      parameters,
      sendAction,
      (progress) => {
        services
          .getService<INotificationsService>('notifications')
          .updateNotification(notificationId, {
            progress,
          });
      }
      // eslint-disable-next-line promise/prefer-await-to-then
    ).then((res) => res.actionId);

    // Do not await : notifications/alerts... should happen in background
    let ignoreErr = false;
    sendPostersPromise
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err) => {
        ignoreErr = true;
        throw err;
      })
      // eslint-disable-next-line promise/always-return, promise/prefer-await-to-then
      .then((actionId) => {
        const creationFinished = () => {
          // Start sync delay so we can get posters with their updated generation status in demands page
          getPosterService<ISignagesSynchronization>('signagesSync').startSynchronizationDelay();
        };

        void services.getService<IActionsService>('actions').pollAction<ConvertDetails>(actionId, {
          onStatusFinished: () => creationFinished(),
          onStatusInError: () => creationFinished(),
        });

        // Alert and notify as soon as posters are created (not end of workflow)
        services
          .getService<IAlertsService>('alerts')
          .alertSuccess(
            i18n.t(`poster.creation.creation_tasks.alerts.success.${sendAction}`) as string
          );

        services
          .getService<INotificationsService>('notifications')
          .updateNotification(notificationId, {
            state: PosterCreationStates.DONE_SUCCESS,
            progress: 100,
          });
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((err) => {
        if (!ignoreErr) {
          // Only log error from custom handling not the send posters promise
          console.error(err);
        }

        services
          .getService<IAlertsService>('alerts')
          .alertError(
            i18n.t(`poster.creation.creation_tasks.alerts.failed.${sendAction}`) as string
          );
        services
          .getService<INotificationsService>('notifications')
          .updateNotification(notificationId, {
            state: PosterCreationStates.DONE_ERROR,
          });
      });

    // Do not await the returned promise, the function should resolve as soon as the send
    // promise is created
    return sendPostersPromise;
  }

  /**
   * Updates a poster.
   * @param poster - the data poster
   * @param values - poster values
   * @param status - Poster status alias (status to initialize)
   * @param parameters - update parameters
   * @returns the action id
   */
  public async updatePoster(
    poster: EditPoster,
    values: Record<string, AttributeValues.AttributeValue>,
    status: string | null,
    parameters: PosterLog.CreationParameters | null
  ): Promise<void> {
    const currentLanguage = services
      .getService<ILanguagesManagerService>('languages')
      .getSelectedLocale();
    const signageRequest: Required<PosterLog.UpdateLog> = {
      itemId: poster.itemId,
      status: status ?? Statutes.TO_BE_PRINTED,
      label: { [currentLanguage ?? 'fr_FR']: values.designation } as Label,
      copies: poster.copies,
      comment: poster.comment ?? null,
      deadlineDate: poster.deadlineDate,
      isTainted: poster.isTainted,
      isArchived: poster.isArchived,
      values: [],
      source: DEFAULT_SIGNAGE_SOURCE,
    };

    // Add values
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        signageRequest.values.push({
          attributeAlias: key,
          value: values[key],
        });
      }
    }

    const res = await logs.partiallyUpdatePosters([signageRequest], parameters);

    const updateFinished = () => {
      // Start sync delay so we can get posters with their updated generation status in demands page
      getPosterService<ISignagesSynchronization>('signagesSync').startSynchronizationDelay();
    };

    void services.getService<IActionsService>('actions').pollAction(res.actionId, {
      onStatusFinished: () => updateFinished(),
      onStatusInError: () => updateFinished(),
    });

    services
      .getService<IAlertsService>('alerts')
      .alertSuccess(i18n.t('poster.demands.editing.save_success') as string);
  }

  /**
   * Handles the main worker messages
   *
   * @param e  - worker message
   */
  private onWorkerMessage = (
    e: MessageEvent<{
      action: string;
      payload: { actionId: string; posterIds: string[]; err: unknown; progress: number };
    }>
  ) => {
    switch (e.data.action) {
      case SendPostersWorkerAction.CreationProgress: {
        const action = this.actions.get(e.data.payload.actionId);
        if (action) {
          action.onProgress(e.data.payload.progress);
        } else if (process.env.NODE_ENV === 'development') {
          console.error(
            `Received ${e.data.action} from worker but no associated callback found for action ${e.data.payload.actionId}`
          );
        }

        break;
      }

      case SendPostersWorkerAction.SendDone: {
        const action = this.actions.get(e.data.payload.actionId);
        if (action) {
          action.callback(e.data.payload.err, e.data.payload.posterIds);
        } else if (process.env.NODE_ENV === 'development') {
          console.error(
            `Received ${e.data.action} from worker but no associated callback found for action ${e.data.payload.actionId}`
          );
        }

        break;
      }

      default:
        break;
    }
  };
}
