/**
 * Events for the cart
 */
export const CartEvents = {
  OPEN_RESOLVE_CONFLICT_DIALOG: 'OPEN_RESOLVE_CONFLICT_DIALOG',
};

/**
 * Tag for an attribute to indicate it should be included in cart summary
 */
export const AttributeCartSummaryTag = 'CartSummary';
