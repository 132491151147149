/**
 * @param {Function} func - Executed function after delay
 * @param {Number} wait - Time to wait in ms, default 250
 * @param {Boolean} immediate - Boolean if first function is directly call or not
 * @returns {(function(): void)|*} - Debounced function to execute
 */
export function debounce(func, wait = 250, immediate) {
  let timeout;
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
