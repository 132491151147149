<template>
  <pui-flex class="poster-pdf-viewer" flex="1" style="position: relative">
    <pui-common-spinner
      v-if="isLoading"
      :size="20"
      :strokeWidth="2"
      :hideMessage="true"
      position="fixed"
    />
    <div v-else-if="!hasError && !isLoading" style="width: 100%; height: 100%">
      <object :data="pdfUrl" style="width: 100%; height: 100%" type="application/pdf"></object>
    </div>
    <div v-else style="width: 100%; height: 100%">
      <default-viewer v-bind="$props" />
    </div>
  </pui-flex>
</template>

<script>
import localApi from '../../../common/api/local';
import { Scopes } from '../../../common/constants/binaries';
import signagesApi from '../../api/signages';
import DefaultViewer from './DefaultViewer';
import { posterViewerMixin } from './posterViewerMixin';

export default {
  name: 'PosterPdfViewer',
  components: { DefaultViewer },
  mixins: [posterViewerMixin],
  data() {
    return {
      pdfUrl: '',
      hasError: false,
      isLoading: false,
    };
  },
  watch: {
    /**
     * Watches the current file to change the displayed pdf to be
     * the one of the current file
     */
    poster(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.pdfUrl) {
          URL.revokeObjectURL(this.pdfUrl);
        }
        this.fetchPdf();
      }
    },
  },
  methods: {
    /**
     * Fetches the current pdfUrl
     */
    async fetchPdf() {
      this.isLoading = true;
      this.hasError = false;
      this.pdfUrl = '';

      try {
        if (this.posterRequest) {
          const url = signagesApi.getBinaryUrl(this.posterRequest.itemId, Scopes.Previews);
          this.pdfUrl = await localApi.createObjectUrlFromRemote(url, 'application/pdf');
        } else {
          throw new Error('received no data from getMedia or wrong type');
        }
      } catch (error) {
        console.error(error);
        this.hasError = true;
      }
      this.isLoading = false;
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    this.fetchPdf();
  },
};
</script>
