import Vue from 'vue';

import { ResolvedResponse } from '../../../core/types/http';
import { PosterLog } from './logsTypes';

const PRINT_API_URL = '/api/poster/v1/print';

export default {
  /**
   * Prints posters
   *
   * @param postersIds - the posters to create
   * @param parameters - creation parameters
   */
  async printPosters(
    postersIds: string[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<string> {
    const response = (await Vue.http.post(`${PRINT_API_URL}`, {
      signageIds: postersIds,
      parameters: parameters ?? {},
    })) as ResolvedResponse<string>;

    return response.body;
  },
};
