<template>
  <pui-dialog
    ref="dialog"
    :title="$t('poster.creation.send_selection_confirmation.title')"
    :showCloseButton="false"
    class="send-selection-confirmation-dialog send-selection-confirmation-dialog__root"
    transition="slide-up"
    width="40%"
  >
    <div slot="dialog-action" class="dialog-action">
      <pui-button outline variant="secondary" @click="onCancel(close)">
        {{ $t('poster.creation.send_selection_confirmation.cancel') }}
      </pui-button>
      <pui-button
        :disabled="
          nbSelectedSignageItems > 0 && nbSelectedSignageItemsInvalid >= nbSelectedSignageItems
        "
        variant="primary"
        @click="onConfirm(close)"
      >
        {{ $t('poster.creation.send_selection_confirmation.confirm') }}
      </pui-button>
    </div>

    <pui-error :picto="errorPicto" class="send-selection-confirmation-dialog__message">
      <!-- Cart has selection -->
      <template v-if="nbSelectedSignageItems > 0">
        <!-- All selection invalid -->
        <template v-if="nbSelectedSignageItemsInvalid >= nbSelectedSignageItems">
          <i18n path="poster.creation.send_selection_confirmation.invalid.primary">
            <template #countMsg>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
                <template #count>{{ nbSelectedSignageItemsInvalid }}</template>
              </i18n>
            </template>
          </i18n>
          <i18n path="poster.creation.send_selection_confirmation.invalid.secondary">
            <template #cancel>
              <i18n
                class="bold-txt"
                path="poster.creation.send_selection_confirmation.cancel"
              ></i18n>
            </template>
          </i18n>
        </template>

        <!-- Selection only partially valid -->
        <template v-else-if="nbSelectedSignageItemsInvalid">
          <i18n
            path="poster.creation.send_selection_confirmation.selection_partially_valid.primary"
          >
            <template #countInvalidMsg>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
                <template #count>{{ nbSelectedSignageItemsInvalid }}</template>
              </i18n>
            </template>
            <template #countSelectedMsg>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
                <template #count>{{ nbSelectedSignageItems }}</template>
              </i18n>
            </template>
          </i18n>
          <i18n
            path="poster.creation.send_selection_confirmation.selection_partially_valid.secondary"
          >
            <template #confirm>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.confirm">
              </i18n>
            </template>
            <template #countValid>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
                <template #count>{{
                  nbSelectedSignageItems - nbSelectedSignageItemsInvalid
                }}</template>
              </i18n>
            </template>
            <template #countSelected>{{ nbSelectedSignageItems }}</template>
          </i18n>
          <i18n
            path="poster.creation.send_selection_confirmation.selection_partially_valid.tertiary"
          >
          </i18n>
        </template>

        <!-- All selection is valid -->
        <template v-else>
          <i18n path="poster.creation.send_selection_confirmation.valid.primary">
            <template #countMsg>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
                <template #count>{{ nbSelectedSignageItems }}</template>
              </i18n>
            </template>
          </i18n>
          <i18n path="poster.creation.send_selection_confirmation.valid.secondary">
            <template #confirm>
              <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.confirm">
              </i18n>
            </template>
            <template #countMsg>{{ nbSelectedSignageItems }}</template>
          </i18n>
        </template>
      </template>

      <!-- Cart has no selection and only partially valid -->
      <template v-else>
        <i18n path="poster.creation.send_selection_confirmation.cart_partially_valid.primary">
          <template #countValid>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{ nbValidSignageItems }}</template>
            </i18n>
          </template>
          <template #countTotal>
            {{ nbTotalSignageItems }}
          </template>
        </i18n>
        <i18n path="poster.creation.send_selection_confirmation.cart_partially_valid.secondary">
          <template #confirm>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.confirm">
            </i18n>
          </template>
          <template #countValid>{{ nbValidSignageItems }}</template>
        </i18n>
      </template>
    </pui-error>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PosterSendSelectionConfirmationDialog',
  props: {
    /**
     * The number of selected posters
     */
    nbSelectedSignageItems: {
      type: Number,
      required: true,
    },
    /**
     * The number of invalid posters that are selected
     */
    nbSelectedSignageItemsInvalid: {
      type: Number,
      required: true,
    },
    /**
     * The number of valid posters in the cart
     */
    nbValidSignageItems: {
      type: Number,
      required: true,
    },
    /**
     * The number of posters in the cart
     */
    nbTotalSignageItems: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      onCancel: () => {},
      onConfirm: () => {},
    };
  },
  computed: {
    /**
     * @returns {string} the error picto
     */
    errorPicto() {
      if (this.nbSelectedSignageItems) {
        if (this.nbSelectedSignageItemsInvalid >= this.nbSelectedSignageItems) {
          // All selection invalid
          return 'mdi-close-circle';
        }

        return this.nbSelectedSignageItemsInvalid
          ? 'mdi-alert' // Selection only partially valid
          : 'mdi-check-circle'; // All selection is valid
      }

      // Cart only partially valid
      return 'mdi-check-circle';
    },
  },
  methods: {
    /**
     * Close dialog
     */
    close() {
      if (this.$refs.dialog) {
        this.$refs.dialog.close();
      }
      this.onCancel = () => {};
      this.onConfirm = () => {};
    },
    /**
     * Opens the dialog
     *
     * @param {object} callbacks - object of user callbacks
     * @param {Function} callbacks.onCancel - called when the user wants to continue editing the poster
     * @param {Function} callbacks.onConfirm - called when the user wants
     * to continue sending the posters
     */
    open({ onCancel, onConfirm } = {}) {
      this.onCancel = onCancel;
      this.onConfirm = onConfirm;

      if (this.$refs.dialog) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>
