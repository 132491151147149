import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { downloadWithBlob, getFilenameFromDownloadUrl } from '../../common/helpers/downloadHelper';
import { IAlertsService } from './../../../platform/services/types';
import { IPDFDownloaderService } from './types';

/**
 * PDF render service
 */
export class PDFDownloaderService implements IPDFDownloaderService {
  /**
   * Download PDF with pdf url in parameter.
   *
   * @param pdfUrl - The pdf url to download
   * @returns resolves nothing or rejects with the failed url
   */
  async download(pdfUrl: string): Promise<void> {
    return downloadWithBlob(pdfUrl);
  }

  /**
   * Download all PDFs (pdfUrls).
   *
   * @param pdfUrls - Array of pdf urls to download
   * @returns if all downloads succeeded
   */
  async downloadAll(pdfUrls: string[]): Promise<boolean> {
    const downloadPromises: Promise<string | null>[] = [];
    let interval = 0;
    for (let i = 0; i < pdfUrls.length; i++) {
      downloadPromises.push(
        new Promise((resolve) => {
          setTimeout(() => {
            this.download(pdfUrls[i])
              // eslint-disable-next-line promise/prefer-await-to-then
              .then(() => resolve(null))
              .catch((error: string) => resolve(error));
          }, (interval += 500));
        })
      );
    }

    const results = await Promise.all(downloadPromises);

    const urlsInError = results.filter((errorUrl) => !!errorUrl) as string[];
    const hasError = urlsInError.length > 0;
    if (!hasError) {
      return true;
    }

    let warningMessage = `<p class='title-alert-download'>${i18n.tc(
      'poster.download_pdf.error.primary',
      urlsInError.length
    )}</p> ${i18n.tc('poster.download_pdf.error.secondary', urlsInError.length)} <br>`;

    urlsInError.forEach((pdfUrl) => {
      const fileName = getFilenameFromDownloadUrl(pdfUrl) || pdfUrl;
      warningMessage += `<a class="download-link" target="_blank" href="${pdfUrl}">${fileName}</a> <br>`;
    });

    services.getService<IAlertsService>('alerts').alertError(warningMessage, null);

    return false;
  }
}
