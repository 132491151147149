export function sortByPriority(a, b) {
  if (a.priority == null && b.priority == null) {
    return 0;
  } else if (a.priority == null) {
    return 1;
  } else if (b.priority == null) {
    return -1;
  }

  return a.priority - b.priority;
}

/**
 * Compare two template with their binding values.
 * @param {Object} templateA : First template object
 * @param {Object} templateB : Second template object
 * @param {String} propertyName : Name of the property to compare
 * @returns the comparison value : 0 (templateA = templateB), 1 (templateA > templateB) or -1 (templateA < templateB)
 */
export function sortByTemplateBinding(templateA, templateB, propertyName) {
  const valueA = templateA && templateA.bindings ? templateA.bindings[propertyName] : null;
  const valueB = templateB && templateB.bindings ? templateB.bindings[propertyName] : null;
  if (valueA == null && valueB == null) {
    return 0;
  } else if (valueA == null) {
    return 1;
  } else if (valueB == null) {
    return -1;
  }

  return valueA - valueB;
}
