import Vue from 'vue';

import { waitOnce, waitUntilAck } from '../../../core/helpers/workerHelpers';
import i18n from '../../../core/i18n';
import services from '../../../core/services';
import store from '../../../core/store';
import {
  IAlertsService,
  IApplicationsService,
  PiivoApplicationLifecycleService,
} from '../../../platform/services/types';
import { debounce } from '../../../utils/debounce';
import { CartEvents } from '../constants/cart';
import { POSTER_APP_NAME } from '../constants/index';
import { CartPoster } from '../types/poster';
import { CART_SYNC_LIMIT, CartSyncServiceActions, CartSyncWorkerActions } from '../workers/shared';
import { CartDbStateManager, CartState } from './cartDbStateManager';
import { ICartStateService } from './types';

/**
 * Admin config for persistence
 */
interface PersistenceConfig {
  enable?: boolean;
  syncIntervalS?: number;
}

/**
 * Default sync interval if the config is missing
 */
const DEFAULT_SYNC_INTERVAL_S = 60;

/**
 * Event bus for cart
 */
const eventBus = new Vue();

/**
 * Service state
 */
interface State {
  cartState: CartState;
  syncActive: boolean;
  syncError: boolean;
}

/**
 * Gets default cart service state
 */
function getDefaultState(): State {
  return {
    cartState: {
      cart: [],
      modifiedTimestamp: 0,
    },
    syncActive: true, // By default we need to load remote state
    syncError: false,
  };
}

/**
 * Handles the current cart state
 */
export class CartStateService implements PiivoApplicationLifecycleService, ICartStateService {
  /**
   * Local state for reactive variables
   */
  private state: State = Vue.observable(getDefaultState());

  /**
   * Vue instance to watch the local cart state to trigger updates
   */
  private vm: Vue | null = null;

  /**
   * The poster cart persistence config
   */
  private persistenceConfig: PersistenceConfig | null | undefined;

  /**
   * If persistent state was already initialized
   */
  private didInitPersistence = false;

  /**
   * The cart sync web worker
   */
  private cartSyncWorker: Worker | null = null;

  /**
   * The cart database state service
   */
  private cartDbStateManager = new CartDbStateManager();

  /**
   * Callback for POSter app mount
   */
  public async onApplicationMount(): Promise<void> {
    const config = services
      .getService<IApplicationsService>('applications')
      ?.getAppDeepParameter<PersistenceConfig>(
        POSTER_APP_NAME,
        'screensSettings',
        'creation.cart.persistence',
        true
      );
    if (config instanceof Error) {
      throw config;
    }
    this.persistenceConfig = config;

    if (this.persistenceConfig?.enable) {
      await this.setupPersistence();
    } else {
      this.state.syncActive = false;
    }
  }

  /**
   * Callback for POSter app before destroyed
   */
  public onBeforeApplicationDestroyed() {
    // Remove watchers and other state created in setupPersistence()
    // to free up system resources
    this.unwatchCart();
    this.cartDbStateManager?.close();
    this.cartSyncWorker?.removeEventListener('message', this.onWorkerMessage);
    this.cartSyncWorker?.terminate();
    this.cartSyncWorker = null;

    // Reset our local state as well:
    // - allows users' carts to be empty by default
    // - prevents memory leaks
    this.state = Vue.observable(getDefaultState());
    this.persistenceConfig = null;

    this.didInitPersistence = false;
  }

  /**
   * Called when the cart changes locally
   */
  private onStateChanged() {
    this.cartSyncWorker?.postMessage({ action: CartSyncServiceActions.CartModified });
    void this.debouncedSaveState();
  }

  /**
   * Saves the cart state to storage
   */
  private debouncedSaveState = debounce(
    async () => {
      await this.cartDbStateManager.saveState(this.state.cartState);
    },
    50, // Debounce enough for a second keystroke
    false
  ) as () => void;

  /**
   * Restores the cart state from storage
   */
  private async restoreState(): Promise<void> {
    // We must individually set the "cart" and "modifiedTimestamp" keys instead of using a single
    // set for the "cartState" parent object, otherwise the cart watcher will be triggered

    const state = await this.cartDbStateManager.restoreState();
    // Stop watching the cart while we change its value, so we won't trigger an update loop
    this.unwatchCart();
    Vue.set(this.state.cartState, 'cart', state.cart);
    this.watchCart();
  }

  /**
   * Restores the cart state from storage
   */
  private async restoreDate(): Promise<void> {
    const state = await this.cartDbStateManager.restoreState();
    Vue.set(this.state.cartState, 'modifiedTimestamp', state.modifiedTimestamp);
  }

  /**
   * Watches the cart state to trigger saves from deep changes
   */
  private watchCart() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const service = this;

    // Watch the cart array to update the store's value
    // Even if components use computed getters/setters to retrieve/set the cart value,
    // they only track assignment https://github.com/vuejs/vue/issues/8867#issuecomment-425698957
    // To track operations like Array.push, we need to watch the array itself
    // https://github.com/vuejs/vue/issues/9509#issuecomment-464460414
    this.vm = new Vue({
      created(this: Vue) {
        this.$watch(
          () => service.state.cartState.cart,
          () => {
            service.onStateChanged();
          },
          { deep: true }
        );
      },
    });
  }

  /**
   * Removes the cart watcher
   */
  private unwatchCart() {
    this.vm?.$destroy();
    this.vm = null;
  }

  /**
   * Handles the main worker messages
   *
   * @param e  - worker message
   */
  private onWorkerMessage = (e: MessageEvent) => {
    switch (e.data.action) {
      case CartSyncWorkerActions.ReloadCartAck:
        if (e.data.payload.success) {
          void this.restoreState();
        } else {
          services
            .getService<IAlertsService>('alerts')
            ?.alertWarning(i18n.t('poster.creation.sync.error.restore') as string, 10 * 1000);
        }
        break;
      case CartSyncWorkerActions.ReloadCartDateAck:
        void this.restoreDate();
        break;
      case CartSyncWorkerActions.SyncInProgress:
        this.state.syncActive = e.data.payload.active;
        this.state.syncError = e.data.payload.error;
        break;
      case CartSyncWorkerActions.SyncLimitReached:
        services
          .getService<IAlertsService>('alerts')
          ?.alertWarning(
            i18n.t('poster.creation.sync.limit_reached', { limit: CART_SYNC_LIMIT }) as string
          );
        break;
      case CartSyncWorkerActions.SyncErr:
        services
          .getService<IAlertsService>('alerts')
          ?.alertWarning(
            i18n.t('poster.creation.sync.error.save', { limit: CART_SYNC_LIMIT }) as string
          );
        break;
      case CartSyncWorkerActions.ConfirmAcceptIncomingState:
        eventBus.$emit(CartEvents.OPEN_RESOLVE_CONFLICT_DIALOG);
        break;
    }
  };

  /**
   * Sets up storage and restores the existing cart from storage.
   * Does nothing if called a second time
   */
  private async setupPersistence() {
    if (this.didInitPersistence) {
      // Prevent duplicate setup from external sources
      return;
    }

    this.didInitPersistence = true;
    this.cartSyncWorker = new Worker(new URL('../workers/cartSync.worker', import.meta.url));

    // Setup listeners
    this.cartSyncWorker.addEventListener('message', this.onWorkerMessage);

    // Init worker and database
    const workerReadyPromise = waitOnce(this.cartSyncWorker, CartSyncWorkerActions.WorkerReady);
    await Promise.all([
      waitOnce(this.cartSyncWorker, CartSyncWorkerActions.WaitingForInit),
      this.cartDbStateManager.openDb(),
    ]);

    // Init config
    this.cartSyncWorker?.postMessage({
      action: CartSyncServiceActions.SendConfig,
      payload: {
        accessToken: store.state.auth.accessToken,
        syncIntervalS: this.persistenceConfig?.syncIntervalS ?? DEFAULT_SYNC_INTERVAL_S,
      },
    });

    // Wait for worker init finish
    await workerReadyPromise;

    // Do initial load
    await waitUntilAck(this.cartSyncWorker, CartSyncServiceActions.ReloadCart);
    this.state.syncActive = false;

    // Start polling
    this.cartSyncWorker?.postMessage({
      action: CartSyncServiceActions.StartPolling,
    });
  }

  /**
   * @inheritdoc
   */
  public set(cart: CartPoster[]): void {
    Vue.set(this.state.cartState, 'cart', cart);

    if (this.persistenceConfig?.enable) {
      this.onStateChanged();
    }
  }

  /**
   * @inheritdoc
   */
  public get(): CartPoster[] {
    return this.state.cartState.cart;
  }

  /**
   * @inheritdoc
   */
  public getSyncActive(): boolean {
    return this.state.syncActive;
  }

  /**
   * @inheritdoc
   */
  public getSyncError(): boolean {
    return this.state.syncError;
  }

  /**
   * @inheritdoc
   */
  public $on(event: string | string[], callback: () => void): void {
    eventBus.$on(event, callback);
  }

  /**
   * @inheritdoc
   */
  public $off(event: string | string[], callback: () => void): void {
    eventBus.$off(event, callback);
  }

  /**
   * @inheritdoc
   */
  public acceptIncomingCart(): void {
    this.cartSyncWorker?.postMessage({
      action: CartSyncServiceActions.ConfirmAcceptIncomingStateAck,
      payload: true,
    });
  }

  /**
   * @inheritdoc
   */
  public acceptCurrentCart(): void {
    this.cartSyncWorker?.postMessage({
      action: CartSyncServiceActions.ConfirmAcceptIncomingStateAck,
      payload: false,
    });
  }
}
