<template>
  <pui-chip
    v-if="value"
    class="status-chip"
    :backgroundColor="color"
    :title="piivoTranslate(value)"
  >
    <span class="message">{{ value | piivoTranslate }}</span>
  </pui-chip>
</template>
<script>
import Vue from 'vue';

import TableAttributeRendererMixin from '../../../../common/components/table/renderers/TableAttributeRendererMixin';
import { getPosterStatusColor } from '../../../helpers/posterHelper';

export default {
  mixins: [TableAttributeRendererMixin],
  computed: {
    /**
     * Get status color
     */
    color() {
      return getPosterStatusColor(this.value);
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
  },
};
</script>
