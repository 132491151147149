<template>
  <plat-dashboard-widget-base :widget="widget" :layout="layout">
    <pui-flex class="pos-widget-creation pos-widget-creation__root" direction="column">
      <pui-flex class="pos-widget-creation__primary-container">
        <pui-flex class="pos-widget-creation__meta" direction="column">
          <div class="pos-widget-creation__header">
            <span class="poster-dashboard-widget__title plat-dashboard-widget__title">
              {{ $t('poster.dashboard.creation.title') }}
            </span>
          </div>
          <p class="pos-widget-creation__subtitle">
            {{ $t('poster.dashboard.creation.subtitle') }}
          </p>
        </pui-flex>
        <pui-flex class="pos-widget-creation__image" justifyContent="center" alignItems="center">
          <div class="pos-widget-creation__cart-container">
            <!-- mdi-pencil-ruler -->
            <svg class="cart-icon" viewBox="0 0 24 24">
              <path
                d="M3 17.25V21H6.75L17.81 9.93L14.06 6.18L3 17.25M22.61 18.36L18.36 22.61L13.16 17.41L14.93 15.64L15.93 16.64L18.4 14.16L19.82 15.58L18.36 17L19.42 18L20.84 16.6L22.61 18.36M6.61 10.83L1.39 5.64L5.64 1.39L7.4 3.16L4.93 5.64L6 6.7L8.46 4.22L9.88 5.64L8.46 7.05L9.46 8.05L6.61 10.83M20.71 7C21.1 6.61 21.1 6 20.71 5.59L18.37 3.29C18 2.9 17.35 2.9 16.96 3.29L15.12 5.12L18.87 8.87L20.71 7Z"
              />
            </svg>
            <div class="cart-count">{{ posterCart.length }}</div>
            <span class="cart-count-subtitle">{{
              $tc('poster.dashboard.creation.count_subtitle', posterCart.length)
            }}</span>
          </div>
        </pui-flex>
      </pui-flex>
      <pui-flex class="pos-widget-creation__action-container">
        <div class="pos-widget-creation__action">
          <router-link :to="{ name: 'posterCreation' }">
            <pui-button
              id="dash-btn-go-to-creation"
              v-track:click="Tracking.DASHBOARD_GO_TO_CREATION"
              variant="primary"
            >
              {{ $t('poster.dashboard.creation.go_to_creation') }}
            </pui-button>
          </router-link>
        </div>
      </pui-flex>
    </pui-flex>
  </plat-dashboard-widget-base>
</template>

<script>
import widgetMixin from '../../../../platform/components/dashboard/widgetMixin';
import ConstantsMixin from '../../mixins/ConstantsMixin';
import { getPosterService } from '../../services';

export default {
  name: 'PosDashboardCreationShortcut',
  mixins: [widgetMixin, ConstantsMixin],
  computed: {
    /**
     * @returns {object[]} the current poster cart
     */
    posterCart() {
      return getPosterService('cartState').get();
    },
  },
};
</script>
