<template>
  <pui-dialog
    ref="dialog"
    :title="$t('poster.creation.cart_limit_dialog.title')"
    :showCloseButton="false"
    class="cart-count-limit-dialog cart-count-limit-dialog__root"
    transition="slide-up"
    width="40%"
  >
    <div slot="dialog-action" class="dialog-action">
      <pui-button outline variant="secondary" @click="close">
        {{ $t('poster.creation.cart_limit_dialog.close') }}
      </pui-button>
    </div>

    <pui-error :picto="'mdi-alert'" class="cart-count-limit-dialog__message-root">
      <template>
        <i18n class="msg" path="poster.creation.cart_limit_dialog.message.primary">
          <template #limit>{{ cartLimit }}</template>
        </i18n>

        <i18n class="msg" path="poster.creation.cart_limit_dialog.message.secondary"> </i18n>

        <ul>
          <i18n
            tag="li"
            class="option"
            path="poster.creation.cart_limit_dialog.message.option_delete"
          >
            <template #delete>
              <i18n class="bold-txt" path="poster.creation.cart_limit_dialog.message.delete">
              </i18n>
            </template>
          </i18n>
          <i18n
            tag="li"
            class="option"
            path="poster.creation.cart_limit_dialog.message.option_send"
          >
            <template #send>
              <i18n class="bold-txt" path="poster.creation.cart_limit_dialog.message.send"> </i18n>
            </template>
          </i18n>
        </ul>
      </template>
    </pui-error>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PosterCartCountLimitDialog',
  props: {
    /**
     * The limit of posters that can exist in the cart
     */
    cartLimit: {
      type: Number,
      required: true,
    },
  },
  methods: {
    /**
     * Close dialog
     */
    close() {
      if (this.$refs.dialog) {
        this.$refs.dialog.close();
      }
    },
    /**
     * Opens the dialog
     */
    open() {
      if (this.$refs.dialog) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>
