<template>
  <div class="bea-preview">
    <div class="preview-viewer">
      <div v-if="!pagesWithContent.length || bindings == null" class="preview-image">
        <img :src="imgPdf" alt="preview" />
      </div>
      <div v-else-if="isNotValid()" class="preview-image">
        <img :src="imgErrorTemplate" alt="preview" />
      </div>
      <pui-svg-signage-preview
        v-else
        ref="preview"
        :autosize="autosize"
        :bindings="bindings"
        :types="types"
        :template="template"
        :showAllPrintables="true"
        :showPage="showPage"
        :pageIndex="internalPageIndex"
        class="preview-image-auto"
      />
    </div>
    <div v-if="pagesWithContent.length > 1" class="preview-tool">
      <pui-pagination
        :total="pagesWithContent.length"
        :pageSize="1"
        :value="internalPageIndex + 1"
        pageMode="determinate"
        class="preview-pagination"
        @change="onPageIndexChanged"
      />
    </div>
  </div>
</template>
<script>
import { ComponentScopes } from 'piivo-poster-engine/src/lib/constants/scopes';
import { TemplatesManager } from 'piivo-poster-engine/src/lib/services/templatesManager';
import { getPagesWithContent } from 'piivo-poster-engine/src/lib/util/template';

import imgErrorTemplate from '../static/img/error-template.jpg';
import imgPdf from '../static/img/pdf.jpg';

export default {
  name: 'PuiDocumentViewer',
  props: {
    template: {
      type: Object,
      required: false,
    },
    bindings: {
      type: Object,
      required: false,
    },
    types: {
      type: Object,
      required: false,
    },
    showTools: {
      type: Boolean,
      default: true,
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    /**
     * If the viewer should detect resizing of the parent element
     * and resize the svg in proportion.
     */
    autosize: {
      type: Boolean,
      default: true,
    },
    /**
     * The index of the page to display
     */
    pageIndex: {
      type: Number,
      default: 0,
    },
    /**
     * The scope to compute components against
     */
    templateScope: {
      type: String,
      required: true,
      validator: (value) => Object.values(ComponentScopes).includes(value),
    },
  },
  data() {
    return {
      internalPageIndex: this.pageIndex,
      imgErrorTemplate,
      imgPdf,
    };
  },
  computed: {
    /**
     * @returns pages with actual content
     */
    pagesWithContent() {
      return getPagesWithContent(this.template);
    },
    /**
     * @returns {string|null} the template id
     */
    templateId() {
      return this.template ? this.template.templateId : null;
    },
  },
  watch: {
    /**
     * Updates internal page index
     * @param {number} nextValue - the new page index
     */
    pageIndex(nextValue) {
      this.internalPageIndex = nextValue;
    },
    /**
     * Resets the page when template changes
     *
     * @param {string|null} nextId - the next template id
     * @param {string|null} prevId - the previous template id
     */
    templateId(nextId, prevId) {
      if (prevId && nextId && nextId !== prevId) {
        // If the template changes (initial template not included), change to first page
        this.onPageIndexChanged(1);
      }
    },
  },
  methods: {
    /**
     * @returns {Element} the main svg element of the poster
     */
    getSvgEl() {
      return this.$refs.preview ? this.$refs.preview.getSvgEl() : null;
    },
    /**
     * @returns {object} the template
     */
    getTemplate() {
      return this.template;
    },
    /**
     * Gets template validity flag.
     */
    isNotValid() {
      // Verifiy if the template hasn't prerequisites
      return TemplatesManager.isNotCorrect(this.template.computedTemplate);
    },
    /**
     * Pagination change callback
     * @param {number} - the new page number (not index)
     */
    onPageIndexChanged(pageNumber) {
      this.internalPageIndex = pageNumber - 1;
      this.$emit('pageChange', this.internalPageIndex);
    },
  },
  /**
   * Before update hook.
   */
  beforeUpdate() {
    // Call a global method to init all parents and ids
    if (this.template !== null) {
      TemplatesManager.initProperties(this.template, this.bindings, { scope: this.templateScope });
    }
  },
};
</script>
