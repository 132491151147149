import { get as idbGet, set as idbSet } from 'idb-keyval';

import { openDb } from '../../../utils/indexedDb';
import { CartPoster } from '../types/poster';

/**
 * Name of our IDB database
 */
const DB_NAME = 'piivo';

/**
 * Name of the IDB key-value store
 */
const KV_STORE_NAME = 'poster';

/**
 * Key for the cart in the store
 */
const CART_KEY = 'cart';

/**
 * Cart state handled by the manager
 */
export interface CartState {
  cart: CartPoster[];
  modifiedTimestamp: number;
}

/**
 * Manages the database connection for the cart state
 */
export class CartDbStateManager {
  /**
   * IndexedDB database for the cart
   */
  private db: IDBDatabase | null = null;

  /**
   * If database was already initialized.
   */
  private didInitDb = false;

  /**
   * Sets up the database connection
   */
  public async openDb(): Promise<void> {
    if (this.didInitDb) {
      return;
    }
    this.didInitDb = true;

    this.db = await openDb(DB_NAME, 1, {
      onUpgrade: (db) => {
        if (!db.objectStoreNames.contains(KV_STORE_NAME)) {
          db.createObjectStore(KV_STORE_NAME);
        }
      },
    });
  }

  /**
   * Closes the connection to the database
   */
  public close() {
    this.db?.close();
    this.db = null;
    this.didInitDb = false;
  }

  /**
   * Connects idb-keyval to our database store
   *
   * @param txMode - store transaction mode
   * @param callback - callback to return our store
   * @returns our store
   */
  private useStore = <T>(
    txMode: IDBTransactionMode,
    callback: (store: IDBObjectStore) => T | PromiseLike<T>
  ): Promise<T> => {
    if (!this.db) {
      throw new Error('CartStateService.setup() not called, database not available');
    }

    return callback(
      this.db.transaction(KV_STORE_NAME, txMode).objectStore(KV_STORE_NAME)
    ) as Promise<T>;
  };

  /**
   * Saves the state to the database
   *
   * @param state - the new cart state
   */
  public async saveState(state: CartState): Promise<void> {
    await idbSet(CART_KEY, state, this.useStore);
  }

  /**
   * Restores the cart state from the database
   */
  public async restoreState(): Promise<CartState> {
    return (
      (await idbGet<CartState>(CART_KEY, this.useStore)) ?? {
        cart: [],
        modifiedTimestamp: 0,
      }
    );
  }
}
