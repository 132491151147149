<template>
  <pui-col v-bind="$attrs" class="poster-dashboard-widget poster-dashboard-widget__root">
    <pui-flex class="poster-dashboard-widget__body" :direction="direction">
      <slot></slot>
    </pui-flex>
  </pui-col>
</template>

<script>
import puiFlex from 'piivo-ui/src/components/container/Flex.vue';

export default {
  name: 'PosterDashboardWidget',
  props: {
    direction: puiFlex.props.direction,
  },
};
</script>
