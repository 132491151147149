import { PosterMimeTypes } from '../../constants';
import DefaultViewer from './DefaultViewer';
import EditSvgViewer from './EditSvgViewer';
import posterPdfViewer from './PdfViewer';

const registeredViewers = new Map();

registerViewer('', DefaultViewer);
registerViewer(PosterMimeTypes.Poster, EditSvgViewer);
registerViewer('application/pdf', posterPdfViewer);

/**
 * Register viewer component under specified name
 *
 * @param {String} name - Component identification
 * @param {Object} viewer - component viewer
 */
export function registerViewer(name, viewer) {
  registeredViewers.set(name, viewer);
}

/**
 * Retrieve viewer component
 * @param {String} name - viewer component identification.
 * @returns {Function} Returns viewer component
 */
export function getViewer(name = null) {
  if (name) {
    name = name.toLowerCase();
  }
  if (!name || !registeredViewers.has(name)) {
    // If haven't registered viewer return default viewer
    return DefaultViewer;
  }
  return registeredViewers.get(name);
}

/**
 * Retrieve list of names registered from the collection
 *
 * @returns {IterableIterator<string>} Returns an array of strings with all viewer component names stored.
 */
export function getViewers() {
  return registeredViewers.keys();
}
