import { ComponentScopes } from 'piivo-poster-engine/src/lib/constants/scopes';
import {
  ErrorCodes as ComponentErrorCodes,
  getComponentsManager,
} from 'piivo-poster-engine/src/lib/services/componentsManager';
import {
  ErrorCodes as FontErrorCodes,
  getFontsManager,
} from 'piivo-poster-engine/src/lib/services/fontsManager';
import Vue from 'vue';
import { Store } from 'vuex';

import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { USER_LOGOUT } from '../../../core/store/modules/coreModule';
import { clearFnCache, memoizePromise } from '../../../utils/memoizePromise';
import { sortByPriority } from '../../../utils/sorting';
import { getAttributeDefaultValue, iterateForm } from '../../common/helpers/formsHelper';
import { filterByScope } from '../../common/helpers/scopeHelper';
import templateFamilies from '../api/templateFamilies';
import templateTypes from '../api/templateTypes';
import { TemplateForm, TemplateFormats, TemplateTypes } from '../api/types';
import { SearchAttributeMapping } from '../types/import';
import { EditableTemplate } from '../types/templates';
import { getPosterService } from '.';
import { IAlertsService } from './../../../platform/services/types';
import { AttributeTypes } from './../../common/constants/index';
import { FamiliesAndTypes, IPosterResourcesService, ITemplatesService } from './types';

/**
 * Contains the form and computed form data for a type
 */
export interface TypeFormData {
  form: TemplateForm.Form;
  defaultValues: {
    [alias: string]: unknown;
  };
  attributeMap: Map<string, TemplateForm.Attribute>;
  valuesTypes: {
    [alias: string]: AttributeTypes;
  };
  valuesKeys: string[];
}

/**
 * Object to contain the form, values, template content, and formats
 * of a given poster type
 */
export interface TypeData extends TypeFormData {
  itemId: string;
  formatGroups: TemplateFormats.FormatGroups[];
  templates: Map<string, EditableTemplate>;
  /**
   * The current type object
   */
  type: TemplateTypes.RootType | TemplateTypes.Type;
}

/**
 * Filters the formats in the groups according to the provided scope
 *
 * @param scopeFilter - scope to filter with
 * @param formatGroups - the format groups
 * @returns the format groups with filtered formats
 */
function filterFormatGroups(
  formatGroups: TemplateFormats.FormatGroups[],
  scopeFilter: string | null
): TemplateFormats.FormatGroups[] {
  if (scopeFilter === null) {
    return formatGroups;
  }

  const clonedFormatGroups = JSON.parse(
    JSON.stringify(formatGroups)
  ) as TemplateFormats.FormatGroups[];

  clonedFormatGroups.forEach((formatGroup) => {
    formatGroup.formats = filterByScope(scopeFilter, formatGroup.formats);
  });

  return clonedFormatGroups;
}

/**
 * Filters the type data according to the provided scope.
 * Mutates the original object's properties
 *
 * @param scopeFilter - scope to filter with
 * @param typeData - the type data to filter
 * @returns the filtered type data
 */
const filterTypeData = (scopeFilter: string | null, typeData: TypeData): TypeData => {
  if (scopeFilter === null) {
    return typeData;
  }

  // Create new object
  return {
    ...typeData,
    formatGroups: filterFormatGroups(typeData.formatGroups, scopeFilter),
  };
};

export class TemplatesService implements ITemplatesService {
  /**
   * @param store - vuex store
   */
  constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  // Old data

  /**
   * Map of type id <> type data
   * Object for reactivity
   */
  typesMap: Record<string, TypeData> = Vue.observable({});
  /**
   * Map of type id <> type data request
   * Object for reactivity
   */
  typesMapRequests: Record<string, Promise<TypeData>> = Vue.observable({});

  familiesAndTypes: FamiliesAndTypes | null = null;
  familiesAndTypesRequest: Promise<FamiliesAndTypes> | null = null;

  /**
   * @inheritdoc
   */
  public async loadFamiliesAndTypes(scopeFilter: string | null): Promise<FamiliesAndTypes> {
    const applyFilter = async (
      res: FamiliesAndTypes | Promise<FamiliesAndTypes>
    ): Promise<FamiliesAndTypes> => {
      const familiesAndTypes = await res;

      // Create new object
      return {
        families: filterByScope(scopeFilter, familiesAndTypes.families),
        rootTypes: new Map(
          [...familiesAndTypes.rootTypes.entries()].map(([familyId, rootTypes]) => {
            const clonedRoots = JSON.parse(JSON.stringify(rootTypes)) as TemplateTypes.RootType[];
            const filteredRoots = filterByScope(scopeFilter, clonedRoots).map((rootType) => {
              rootType.children = filterByScope(scopeFilter, rootType.children);
              return rootType;
            });
            return [familyId, filteredRoots];
          })
        ),
      };
    };

    if (this.familiesAndTypes) {
      return applyFilter(this.familiesAndTypes);
    }
    if (this.familiesAndTypesRequest) {
      return applyFilter(this.familiesAndTypesRequest);
    }

    const requestPromise = (async () => {
      const rootTypes = new Map<string, TemplateTypes.RootType[]>();

      const families = await this.loadFamilies();

      // Load all families types
      const typesPromises = families.map(async (family) => {
        const types = await this.loadFamilyRootTypes(family.itemId);
        rootTypes.set(family.itemId, types);
      });

      // Launch all types promises
      await Promise.all(typesPromises);

      return {
        families,
        rootTypes,
      };
    })();

    this.familiesAndTypesRequest = requestPromise;
    this.familiesAndTypes = await requestPromise;
    this.familiesAndTypesRequest = null;

    return applyFilter(this.familiesAndTypes);
  }

  /**
   * @inheritdoc
   */
  public getTypeData(typeId: string, scopeFilter: string | null): TypeData | null {
    const res = this.typesMap[typeId] ?? null;
    return res ? filterTypeData(scopeFilter, res) : null;
  }

  /**
   * @inheritdoc
   */
  public async loadTypeData(
    reqType: Pick<TemplateTypes.RootType | TemplateTypes.Type, 'itemId'>,
    scopeFilter: string | null
  ): Promise<TypeData> {
    if (this.typesMap[reqType.itemId]) {
      return filterTypeData(scopeFilter, this.typesMap[reqType.itemId]);
    }
    if (Object.hasOwnProperty.call(this.typesMapRequests, reqType.itemId)) {
      return filterTypeData(scopeFilter, await this.typesMapRequests[reqType.itemId]);
    }

    const requestPromise = (async () => {
      const type: TypeData = {
        form: null,
        itemId: reqType.itemId,
        type: reqType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      // Form loading for the current type
      // eslint-disable-next-line promise/prefer-await-to-then, promise/always-return
      const getFormPromise = this.loadTypeFormData(reqType.itemId).then((formData) => {
        Object.assign(type, formData);
      });

      // Format groups loading for the current type
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      const getTypePromise = new Promise<void>((resolve, reject) => {
        void (async () => {
          try {
            // Do not apply scope filter : the filter should be applied on demand because
            // the type's format groups can be accessed with different scopes
            const formatGroups = await this.loadTypeFormatGroups(reqType.itemId, null);
            type.formatGroups = formatGroups;

            // Load templates content for each formats
            const templates = await this.loadTemplatesByType(reqType.itemId, formatGroups);
            type.templates = templates;
            resolve();
          } catch (err) {
            reject(err);
          }
        })();
      });

      // Launch all loading promises
      await Promise.all([getFormPromise, getTypePromise]);
      return type;
    })();

    this.typesMapRequests[reqType.itemId] = requestPromise;
    try {
      this.typesMap[reqType.itemId] = await requestPromise;
    } finally {
      delete this.typesMapRequests[reqType.itemId];
    }

    return filterTypeData(scopeFilter, this.typesMap[reqType.itemId]);
  }

  // End old data

  /**
   * Maps a family id to the family
   */
  private families: Record<string, TemplateTypes.FamilyType> = Vue.observable({});

  /**
   * Maps a type id to the type
   */
  private types: Record<string, TemplateTypes.Type> = Vue.observable({});

  /**
   * Loads all families
   *
   * @returns all families
   */
  private loadFamiliesFromApi = memoizePromise(
    async (): Promise<TemplateTypes.FamilyType[]> =>
      templateFamilies.getAllTemplateFamilies().then((res) => res.body)
  );

  /**
   * @inheritdoc
   */
  public async loadFamilies() {
    const families = await this.loadFamiliesFromApi();
    families.forEach((family) => {
      Vue.set(this.families, family.itemId, family);
    });

    return families;
  }

  /**
   * @inheritdoc
   */
  public loadFamilyRootTypes = memoizePromise(
    async (familyId: string): Promise<TemplateTypes.RootType[]> => {
      const res = await templateFamilies.getTemplateFamilyTypes(familyId);
      const allTypes = res.body;

      allTypes.forEach((type) => {
        Vue.set(this.types, type.itemId, type);
      });

      const types = this.buildTypeTreeFromSubTypes(allTypes);
      return types;
    }
  );

  /**
   * Loads a family
   *
   * @param familyId - the family id
   * @returns the family
   */
  private loadFamilyFromApi = memoizePromise(
    async (familyId: string): Promise<TemplateTypes.FamilyType> =>
      templateFamilies
        .getTemplateFamily(familyId)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((res) => res.body)
  );

  /**
   * @inheritdoc
   */
  public async loadFamily(familyId: string): Promise<TemplateTypes.FamilyType> {
    if (this.families[familyId]) {
      return this.families[familyId];
    }

    const family = await this.loadFamilyFromApi(familyId);

    Vue.set(this.families, familyId, family);

    return family;
  }

  /**
   * Loads a type
   *
   * @param typeId - the id
   * @returns the type
   */
  private loadTypeFromApi = memoizePromise(
    async (typeId: string): Promise<TemplateTypes.Type> =>
      templateTypes
        .getType(typeId)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((res) => res.body)
  );

  /**
   * @inheritdoc
   */
  public async loadType(typeId: string): Promise<TemplateTypes.Type> {
    if (this.types[typeId]) {
      return this.types[typeId];
    }

    const type = await this.loadTypeFromApi(typeId);

    Vue.set(this.types, typeId, type);

    return type;
  }

  /**
   * @inheritdoc
   */
  public async loadTypeByAlias(typeAlias: string): Promise<TemplateTypes.Type | null> {
    // There is no api to load type by alias. Workaround:
    // Load all families, get their types and match by alias

    const allFamilies = await this.loadFamilies();

    for (const family of allFamilies) {
      const familyRootTypes = await this.loadFamilyRootTypes(family.itemId);
      const newType = this.getTemplateTypeByAlias(familyRootTypes, typeAlias);

      if (newType) {
        return newType;
      }
    }

    return null;
  }

  /**
   * @inheritdoc
   */
  public loadTypeFormData = memoizePromise(async (typeId: string): Promise<TypeFormData> => {
    const res = await templateTypes.getForm(typeId);

    const formData: TypeFormData = {
      form: null as any,
      defaultValues: {},
      attributeMap: new Map(),
      valuesTypes: {},
      valuesKeys: [],
    };

    const { body: form } = res;
    formData.form = form;

    // Init default values, attribute types and keys (aliases)
    formData.defaultValues = {};
    formData.attributeMap = new Map<string, TemplateForm.Attribute>();
    formData.valuesTypes = {};
    formData.valuesKeys = [];

    iterateForm(form, (attribute, isInput) => {
      const defaultValue = getAttributeDefaultValue(attribute);
      formData.defaultValues[attribute.alias] = defaultValue;
      formData.attributeMap.set(attribute.alias, attribute);
      formData.valuesTypes[attribute.alias] = attribute.type;
      formData.valuesKeys.push(attribute.alias);

      // Legacy compat: add search onAttributeResult if missing
      this.initCompatSearchResultConfig(attribute, isInput);
    });

    return formData;
  });

  /**
   * @inheritdoc
   */
  public initCompatSearchResultConfig(attribute: TemplateForm.Attribute, isInput: boolean): void {
    if (!attribute.search) {
      return;
    }

    attribute.search.modes.forEach((mode) => {
      // Don't do anything if the result config already exists
      if (mode.search.onAttributeResult) {
        return;
      }

      if (isInput) {
        // If the attribute is from an input group, just
        // select the valueField column from the result, and set it on the attribute
        const valueField = attribute.options.valueField || attribute.alias;
        mode.search.onAttributeResult = {
          attributeMappings: {
            [attribute.alias]: {
              select: valueField,
              output: '',
              updateMode: 'replace',
            },
          },
        };
      } else {
        // If the attribute is NOT from an input group, map all the result columns
        // to attributes with the same aliases
        const attributeMappings: {
          [alias: string]: SearchAttributeMapping;
        } = {};
        mode.search.columns.forEach((col) => {
          attributeMappings[col.alias] = { select: col.alias, output: '', updateMode: 'replace' };
        });

        mode.search.onAttributeResult = {
          attributeMappings,
        };
      }
    });
  }

  /**
   * Loads the format groups of a type. The result is cached.
   *
   * @param typeId - the id of the type
   * @returns the format groups of the type
   */
  private loadTypeFormatGroupsFromApi = memoizePromise(
    (typeId: string): Promise<TemplateFormats.FormatGroups[]> =>
      templateTypes
        .getFormatGroupsByTypeId(typeId)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((res) => res.body)
  );

  /**
   * @inheritdoc
   */
  public loadTypeFormatGroups = memoizePromise(
    async (typeId: string, scopeFilter: string | null): Promise<TemplateFormats.FormatGroups[]> => {
      const res = await this.loadTypeFormatGroupsFromApi(typeId);
      return filterFormatGroups(res, scopeFilter);
    }
  );

  /**
   * @inheritdoc
   */
  public async getDefaultFormData(): Promise<TypeFormData | null> {
    const allFamilies = await this.loadFamilies();
    if (!allFamilies.length) {
      return null;
    }
    const familyRootTypes = await this.loadFamilyRootTypes(allFamilies[0].itemId);
    if (!familyRootTypes || familyRootTypes.length) {
      return null;
    }
    const defaultType = this.getDefaultTemplateType(familyRootTypes, null);
    if (!defaultType) {
      return null;
    }
    const formData = await this.loadTypeFormData(defaultType.itemId);
    return formData;
  }

  /**
   * Clears cache
   */
  private clearCache(): void {
    this.familiesAndTypes = null;
    this.typesMap = Vue.observable({});
    this.families = Vue.observable({});
    this.types = Vue.observable({});

    clearFnCache(this.loadFamiliesFromApi);
    clearFnCache(this.loadFamilyRootTypes);
    clearFnCache(this.loadTypeFromApi);
    clearFnCache(this.loadTypeFormData);
    clearFnCache(this.loadTypeFormatGroupsFromApi);
    clearFnCache(this.loadTypeFormatGroups);
  }

  /**
   * Loads all template contents for a type and all formats.
   * @param typeId The type identifier.
   * @param formatGroups the format groups containing formats.
   * @returns a map of format ids to templates
   */
  private async loadTemplatesByType(
    typeId: string,
    formatGroups: TemplateFormats.FormatGroups[]
  ): Promise<Map<string, EditableTemplate>> {
    const loadTemplatePromises: Promise<{
      formatId: string;
      template: EditableTemplate;
    }>[] = [];
    const templateResourceUrl =
      getPosterService<IPosterResourcesService>('posterResourcesService').getTemplateResourceUrl();

    for (const formatGroup of formatGroups) {
      for (const format of formatGroup.formats) {
        loadTemplatePromises.push(
          templateTypes
            .getTemplateByTypeAndFormat(typeId, format.itemId)
            .then((res) => {
              return getComponentsManager().parseTemplateComponents(res.body, null, {
                templateUrl: templateResourceUrl,
                scope: ComponentScopes.Preview,
              }) as Promise<unknown> as Promise<EditableTemplate>;
            })
            .then((template) => {
              return { formatId: format.itemId, template: template };
            })
        );
      }
    }

    // Load all templates and register templates fonts
    const templatesResponse = await Promise.all(loadTemplatePromises);

    const formatTemplates = new Map<string, EditableTemplate>();
    for (const templateResponse of templatesResponse) {
      if (templateResponse != null) {
        formatTemplates.set(templateResponse.formatId, templateResponse.template);
      }
    }

    try {
      await getFontsManager().registerAllTemplatesFontsIfEnabled(
        Array.from(formatTemplates.values()).map((t) => t.resolvedTemplate)
      );
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw { fontsError: true, error };
    }

    return formatTemplates;
  }

  /**
   * Receives array of sub types and builds array of root types
   * with their sub type children.
   * The root types are sorted by priority
   *
   * @param subTypes - list of sub types
   * @return The built types and sorted list.
   */
  private buildTypeTreeFromSubTypes(subTypes: TemplateTypes.Type[]): TemplateTypes.RootType[] {
    if (!subTypes || !subTypes.length) {
      return [];
    }

    const rootTypes: TemplateTypes.RootType[] = [];

    // Sort sub-types and build types list
    subTypes.sort(sortByPriority);

    subTypes.forEach((subType) => {
      if (!subType.parent) {
        // The sub type is a root type
        const parentType = subType as TemplateTypes.RootType;
        parentType.children = [];
        rootTypes.push(parentType);
        return;
      }

      // Sub-type with parent
      let parentType = rootTypes.find(
        (rootType) => rootType.itemId === (subType.parent && subType.parent.itemId)
      );
      const newSubType = { ...subType }; // Clone subtype (to prevent reference modification)

      if (parentType) {
        // TemplateTypes.Type already exists => add sub-type (clear parent property to prevent circular object)
        newSubType.parent = null;
        parentType.children.push(newSubType);
      } else {
        // Create new type (parent) with the sub-type as children
        parentType = {
          ...(newSubType.parent as TemplateTypes.Type),
          children: [],
        };
        newSubType.parent = null; // (clear parent property to prevent circular object)
        parentType.children = [newSubType];

        rootTypes.push(parentType);
      }
    });

    // Sort types
    rootTypes.sort(sortByPriority);

    return rootTypes;
  }

  /**
   * @inheritdoc
   */
  public getDefaultTemplateType(
    types: TemplateTypes.RootType[],
    currentTypeId: string | null
  ): TemplateTypes.RootType | TemplateTypes.Type | null {
    let selectedType = null;
    // Fallback to first type by default if exists
    if (types != null && types.length > 0) {
      // We can use a root type or a child type
      selectedType =
        types[0].children && types[0].children.length > 0 ? types[0].children[0] : types[0];
    }

    if (currentTypeId != null) {
      // Search current type to keep it
      for (const rootType of types) {
        if (rootType.itemId === currentTypeId) {
          selectedType = rootType;
        } else if (rootType.children && rootType.children.length) {
          const searchType = rootType.children.find((type) => type.itemId === currentTypeId);
          if (searchType != null) {
            selectedType = searchType;
          }
        }
      }
    }

    return selectedType;
  }

  /**
   * @inheritdoc
   */
  public getDefaultTemplateTypeFormat(
    formatGroups: TemplateFormats.FormatGroups[],
    currentFormatId: string | null = null
  ): TemplateFormats.Format | null {
    // Fallback to first format by default if exists
    let defaultFormat =
      formatGroups != null && formatGroups.length > 0 && formatGroups[0].formats.length > 0
        ? formatGroups[0].formats[0]
        : null;

    if (currentFormatId != null) {
      // Search current format to keep it
      for (const formatGroup of formatGroups) {
        const searchFormat = formatGroup.formats
          ? formatGroup.formats.find((format) => format.itemId === currentFormatId)
          : null;
        if (searchFormat != null) {
          defaultFormat = searchFormat;
        }
      }
    }

    return defaultFormat;
  }

  /**
   * Get the type in types list parameter with the corresponding alias.
   * @param {Object} types list
   * @param {String} typeAlias (alias searched)
   */
  private getTemplateTypeByAlias(
    types: TemplateTypes.RootType[],
    typeAlias: string
  ): TemplateTypes.RootType | TemplateTypes.Type | null {
    let newType: TemplateTypes.RootType | TemplateTypes.Type | null = null;
    if (typeAlias && types != null) {
      types.forEach(function (type) {
        if (type.alias === typeAlias) {
          // Select type or first children
          newType = type.children && type.children.length > 0 ? type.children[0] : type;
        } else {
          // Search in children
          const matchedChild = type.children.find((c) => c.alias === typeAlias);
          newType = matchedChild != null ? matchedChild : newType;
        }
      });
    }

    return newType;
  }

  /**
   * @inheritdoc
   */
  public onTemplateComponentError(errorCode: string, data: { [x: string]: any }): void {
    switch (errorCode) {
      case ComponentErrorCodes.RETRIEVE_COMPONENT: {
        const errorMessage = i18n.t('poster.templates.error.retrieve_component', {
          url: data.url,
        }) as string;
        services.getService<IAlertsService>('alerts').alertError(errorMessage);
        break;
      }
    }
  }

  /**
   * @inheritdoc
   */
  public onTemplateFontError(errorCode: string, data: { [x: string]: any }): void {
    switch (errorCode) {
      case FontErrorCodes.RETRIEVE_FONT:
        services
          .getService<IAlertsService>('alerts')
          ?.alertError(i18n.t('poster.fonts.error.retrieve_font', { url: data.url }) as string);
        break;
    }
  }
}
