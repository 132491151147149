import posterCartCountLimitDialog from './creation/CartCountLimitDialog';
import posterResolveCartConflictDialog from './creation/ResolveCartConflictDialog';
import posterSendCountConfirmationDialog from './creation/SendLimitedCountConfirmationDialog';
import posterSendSelectionConfirmationDialog from './creation/SendSelectionConfirmationDialog';
import posterDashboardWelcomeWidget from './dashboard/DashboardWelcomeWidget';
import posterDashboardWidget from './dashboard/DashboardWidget';
import puiDocumentViewer from './DocumentViewer';
import posterGalleryThumbnail from './GalleryThumbnail';
import posterForm from './search/SearchForm';
import posterModeSelector from './search/SearchModeSelector';
import posterSearchPanel from './search/SearchPanel';
import posterSearchSummary from './search/SearchSummary';
import posterTypeGallery from './selector/TypeGallery';
import posterValidationAlert from './ValidationAlert';

export {
  puiDocumentViewer,
  posterGalleryThumbnail,
  posterSearchPanel,
  posterSearchSummary,
  posterValidationAlert,
  posterDashboardWidget,
  posterDashboardWelcomeWidget,
  posterModeSelector,
  posterForm,
  posterTypeGallery,
  posterSendSelectionConfirmationDialog,
  posterSendCountConfirmationDialog,
  posterResolveCartConflictDialog,
  posterCartCountLimitDialog,
};

export const components = [
  puiDocumentViewer,
  posterGalleryThumbnail,
  posterSearchPanel,
  posterSearchSummary,
  posterValidationAlert,
  posterDashboardWidget,
  posterDashboardWelcomeWidget,
  posterModeSelector,
  posterForm,
  posterTypeGallery,
  posterSendSelectionConfirmationDialog,
  posterSendCountConfirmationDialog,
  posterResolveCartConflictDialog,
  posterCartCountLimitDialog,
];
