export interface MemoizePromiseOptions {
  /**
   * If promise rejections should be cached.
   */
  cacheRejection?: boolean;
}

export type AsyncFn<ReturnType = any> = (...args: readonly any[]) => Promise<ReturnType>;

/**
 * Maps a memoized function, to its cache
 */
const memoizedFnCache = new WeakMap<AsyncFn, Map<string, any>>();

/**
 * Memoizes an async function.
 * Each call is cached by its arguments
 *
 * @param fn - function to memoize
 * @param opts - memoize options
 * @returns the memoized function
 */
// eslint-disable-next-line no-use-before-define
export function memoizePromise<WrappedFn extends AsyncFn<ReturnType>, ReturnType = any>(
  fn: WrappedFn,
  { cacheRejection }: MemoizePromiseOptions = {}
): WrappedFn {
  const cache = new Map<string, Promise<ReturnType>>();

  const memoizedFn = async function (this: any, ...args: any[]): Promise<ReturnType> {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return cache.get(key)!;
    }

    const promise = fn.apply(this, args);

    cache.set(key, promise);

    try {
      return await promise;
    } catch (err) {
      if (!cacheRejection) {
        cache.delete(key);
      }
      throw err;
    }
  } as WrappedFn;

  memoizedFnCache.set(memoizedFn, cache);

  return memoizedFn;
}

/**
 * Clears the cache of a memoized function
 *
 * @param fn - a memoized function
 */
export function clearFnCache(fn: AsyncFn): void {
  const cache = memoizedFnCache.get(fn);
  if (!cache) {
    return;
  }

  cache.clear();
}
