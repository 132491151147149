import Permissions from '../constants/permissions';
import Tracking from '../constants/tracking';

/**
 * Constants mixin : Expose constants data.
 */
export default {
  data() {
    return {
      /**
       * Expose a permissions data (Object with permissions constants)
       */
      Permissions: Permissions,
      /**
       * Expose a tracking data (Object with tracking constants)
       */
      Tracking: Tracking,
    };
  },
};
