import { PosterLog } from '../api/logsTypes';
import printerApi from '../api/print';
import { IPrinterService } from './types';

/**
 * Printer service
 */
export class PrinterService implements IPrinterService {
  /**
   * @inheritdoc
   */
  async printPosters(
    posterIds: string[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<string> {
    return printerApi.printPosters(posterIds, parameters);
  }
}
