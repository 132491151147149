import { CommonTableEditors } from '../../../../common/components/table/editors';
import FormatsCopiesAttributeEditor from './FormatsCopiesAttributeEditor';

class PosterTableEditorsClass extends CommonTableEditors {
  constructor() {
    super();
    this.registerEditor('FormatsCopies', FormatsCopiesAttributeEditor);
  }
}

export const PosterTableEditors = new PosterTableEditorsClass();
