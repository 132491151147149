import Vue from 'vue';

import { AttributeValues } from '../../common/api/attributes';
import { ResolvedResponse, Response } from './../../../core/types/http';
import { ActionDetails } from './../../common/api/actions';
import { PosterLog } from './logsTypes';
import { LogsDefinitions } from './types';

const LOGS_API_URL = '/api/signages/logs';

export namespace Convert {
  /**
   * Conversion result status
   */
  export enum ResultStatus {
    OK = 'OK',
    WARNING = 'WARNING',
  }

  /**
   * Pdf conversion result
   */
  export interface PdfResult {
    status: ResultStatus;
    urls: string[];
  }
}

export default {
  /**
   * Starts rendering the posters
   * @param posterIds - the poster ids
   */
  async renderPosters(posterIds: string[]): Promise<string> {
    const response = (await Vue.http.post(
      `${LOGS_API_URL}/render`,
      posterIds
    )) as ResolvedResponse<string>;

    return response.body;
  },

  /**
   * Get logs with paging parameters.
   *
   * @param index - Page index
   * @param pageSize - Page size
   */
  async getLogs(index: number, pageSize: number) {
    return Vue.http.get(`${LOGS_API_URL}/search?pageSize=${pageSize}&pageIndex=${index}`);
  },

  /**
   * Get log details with the log Id.
   *
   * @param logId - Log id
   * @param language - Language
   */
  async getLogDetails(logId: string, language: string) {
    return Vue.http.get(`${LOGS_API_URL}/${logId}/details?lang=${language}`);
  },

  async getSignagesLogs(
    signageIds: string[],
    forEdition: boolean
  ): Response<PosterLog.PosterLog[] | PosterLog.EditablePosterLog[]> {
    return Vue.http.get(LOGS_API_URL, {
      params: {
        ids: signageIds.join(','),
        forEdition,
      },
    }) as Response<PosterLog.PosterLog[] | PosterLog.EditablePosterLog[]>;
  },

  /**
   * Get log thumbnail  with the log Id.
   *
   * @param logId - Log id
   */
  async getLogThumbnail(logId: string) {
    return Vue.http.get(`${LOGS_API_URL}/${logId}/thumbnail`, { responseType: 'blob' });
  },

  /**
   * Delete a list of logs with their ids.
   *
   * @param toDelete - Array of logs id
   */
  async deleteLogs(toDelete: unknown[]) {
    return Vue.http.delete(`${LOGS_API_URL}/`, { body: toDelete });
  },

  /**
   * Archive a list of logs with their ids.
   *
   * @param {Array} toArchive - Array of logs id
   */
  async archiveLogs(toArchive: unknown) {
    return Vue.http.post(`${LOGS_API_URL}/archive`, toArchive);
  },

  /**
   * Get logs table definition.
   */
  async getLogsTableDefinition() {
    return Vue.http.get(`${LOGS_API_URL}/definitions/table`);
  },

  /**
   * Get logs filters definition.
   */
  async getLogsFiltersDefinition(): Response<LogsDefinitions.FiltersDefinition> {
    return Vue.http.get(
      `${LOGS_API_URL}/definitions/filters`
    ) as Response<LogsDefinitions.FiltersDefinition>;
  },

  /**
   * Get logs details definition.
   */
  async getLogsDetailsDefinition() {
    return Vue.http.get(`${LOGS_API_URL}/definitions/details`);
  },

  /**
   * Partially updates posters
   *
   * @param posters - the posters to update
   * @param parameters - update parameters
   * */
  async partiallyUpdatePosters<TResult = unknown>(
    posters: PosterLog.UpdateLog[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<ActionDetails<TResult>> {
    const res = (await Vue.http.patch(`${LOGS_API_URL}`, {
      signages: posters,
      parameters,
    })) as ResolvedResponse<ActionDetails<TResult>>;

    return res.body;
  },

  /**
   * Starts an async conversion of posters.
   *
   * @param idsToConvert  Array of poster id
   * @param parameters - Conversion parameters
   * @returns the action for the conversion
   */
  async convertToPdfAsync<TResult = unknown>(
    idsToConvert: string[],
    parameters: { values: AttributeValues.ValueObject[] | null; [x: string]: any }
  ): Promise<ActionDetails<TResult>> {
    const res = (await Vue.http.post(`${LOGS_API_URL}/convertasync/pdf`, {
      signageIds: idsToConvert,
      parameters,
    })) as ResolvedResponse<ActionDetails<TResult>>;

    return res.body;
  },
};
