import Vue from 'vue';

import { Response } from './../../../core/types/http';
import { TemplateTypes } from './types';

const TEMPLATE_FAMILIES_API_URL = '/api/signages/templatefamilies';

export default {
  /**
   * @param language - The language filter
   * @returns all template families (limited to 100)
   */
  async getAllTemplateFamilies(): Response<TemplateTypes.FamilyType[]> {
    return Vue.http.get(
      `${TEMPLATE_FAMILIES_API_URL}?sorting=${encodeURIComponent(
        '[{"priority":"asc"},{"label":"asc"}]'
      )}`
    ) as Response<TemplateTypes.FamilyType[]>;
  },

  /**
   * @param familyId - Family Id
   * @returns all the family's types
   */
  async getTemplateFamilyTypes(familyId: string | number): Response<TemplateTypes.Type[]> {
    return Vue.http.get(`${TEMPLATE_FAMILIES_API_URL}/${familyId}/types`) as Response<
      TemplateTypes.Type[]
    >;
  },

  /**
   * @param familyId - family id
   * @returns the family's details
   */
  async getTemplateFamily(familyId: string | number): Response<TemplateTypes.FamilyType> {
    return Vue.http.get(
      `${TEMPLATE_FAMILIES_API_URL}/${familyId}`
    ) as Response<TemplateTypes.FamilyType>;
  },
};
