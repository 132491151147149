const FILE_NAME_REGEX = /filename="(.*)"/;

export function getFilenameFromDownloadUrl(downloadUrl) {
  const res = FILE_NAME_REGEX.exec(decodeURIComponent(downloadUrl));
  return res ? res[1] : '';
}

/**
 * Function to simulate click in link in order to download file
 * @param {string} downloadUrl - url to download file
 * @param {string} fileName - filename to download file
 * @returns {void}
 */
export function downloadWithLink(downloadUrl, filename = '') {
  const downloadableFileLink = document.createElement('a');
  document.body.appendChild(downloadableFileLink);
  downloadableFileLink.href = downloadUrl;
  downloadableFileLink.style = 'display: none';
  downloadableFileLink.target = '_blank';
  downloadableFileLink.download = filename;
  const clickListener = (e) => {
    // Prevent click propagating to any document listeners
    // since we don't want to provoke any side effects
    e.stopPropagation();
  };
  downloadableFileLink.addEventListener('click', clickListener);
  downloadableFileLink.click();
  document.body.removeChild(downloadableFileLink);
  downloadableFileLink.removeEventListener('click', clickListener);
}

/**
 * Function to download file with blob
 * @param downloadUrl
 * @returns {Promise<void>} resolves with nothing, or throws with the provided url
 */
export async function downloadWithBlob(downloadUrl) {
  return new Promise((resolve, reject) => {
    const contentRequest = new XMLHttpRequest();
    contentRequest.open('GET', downloadUrl);
    contentRequest.responseType = 'blob';
    contentRequest.onload = async () => {
      if (contentRequest.status >= 200 && contentRequest.status < 300) {
        const filename = getFilenameFromDownloadUrl(downloadUrl);
        const response = contentRequest.response || contentRequest.responseText;
        const url = URL.createObjectURL(response);
        downloadWithLink(url, filename);
        URL.revokeObjectURL(url);
        resolve();
      } else {
        reject(downloadUrl);
      }
    };
    contentRequest.onerror = async () => {
      reject(downloadUrl);
    };
    contentRequest.send();
  });
}
