import { CreationActions } from '../constants';

/**
 * Prefix for the send action aliases
 */
const ACTION_ALIAS_PREFIX = 'poster_action_';

/**
 * @param sendAction - the internal send action
 * @returns the send action's action alias
 */
export const GetSendActionAlias = (sendAction: CreationActions): string =>
  `${ACTION_ALIAS_PREFIX}${sendAction}`;
