var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-flex',{staticClass:"poster-type-gallery poster-type-gallery__root",attrs:{"direction":"column"}},[(_vm.loadingFamilies)?_c('pui-common-spinner',{staticClass:"poster-type-gallery__root-spinner",attrs:{"position":"fixed"}}):(_vm.errorLoadingFamilies)?_c('pui-error',{attrs:{"position":"fixed"}},[_c('i18n',{attrs:{"path":"poster.type_gallery.families.error.primary"}}),_c('i18n',{attrs:{"path":"poster.type_gallery.families.error.secondary"},scopedSlots:_vm._u([{key:"btnReload",fn:function(){return [_c('span',{staticClass:"pui-error__btn-reload",on:{"click":_vm.getFamilies}},[_vm._v(_vm._s(_vm.$t('poster.type_gallery.families.error.btnReload')))])]},proxy:true}])})],1):(
      _vm.families.length === 0 ||
      (_vm.families.length === 1 && !_vm.loadingTypes && !_vm.currentFamilyTypes.length)
    )?_c('pui-error',{attrs:{"position":"fixed","picto":"mdi-alert"}},[_c('i18n',{attrs:{"path":"poster.type_gallery.families.no_families.primary"}}),_c('i18n',{attrs:{"path":"poster.type_gallery.families.no_families.secondary"}})],1):[(_vm.$scopedSlots.title)?_c('div',{staticClass:"poster-type-gallery__title"},[_vm._t("title")],2):_vm._e(),(_vm.families.length > 1)?_c('pui-flex',[_c('poster-family-carousel',_vm._b({ref:"familyCarousel",staticClass:"poster-type-gallery__family-carousel",attrs:{"items":_vm.families,"value":_vm.currentFamily ? [_vm.currentFamily] : []},on:{"change":_vm.onFamilyChanged}},'poster-family-carousel',_vm.familyCarouselProps,false))],1):_vm._e(),(_vm.currentFamily)?_c('pui-gallery',{key:_vm.currentFamily.itemId,ref:"gallery",staticClass:"poster-type-gallery__gallery",attrs:{"items":_vm.currentFamilyTypes,"getItemId":function (type) { return type.itemId; },"getItemDimensions":function () { return ({ width: 0, height: 0 }); },"loading":_vm.loadingTypes,"initialMode":_vm.GalleryMode.GRID,"hasMoreItems":false,"hasError":_vm.errorLoadingTypes,"gridProps":{ offsetHeight: _vm.META_HEIGHT_PX },"showConfig":false,"groupedItems":true,"defaultGridCount":7},scopedSlots:_vm._u([{key:"groupSelf",fn:function(ref){
    var item = ref.item;
return [_c('i18n',{staticClass:"poster-type-gallery__group-title",attrs:{"path":"poster.type_gallery.types.group.title","tag":"div"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('span',{staticClass:"poster-type-gallery__group-title-primary"},[_vm._v(" "+_vm._s(_vm.piivoTranslate(item))+" ")])]},proxy:true},{key:"children",fn:function(){return [_c('span',{staticClass:"poster-type-gallery__group-title-secondary"},[_vm._v(" "+_vm._s(_vm.$tc('poster.type_gallery.types.group.children', item.children.length, { count: item.children.length, }))+" ")])]},proxy:true}],null,true)})]}},{key:"default",fn:function(ref){
    var item = ref.item;
return [_c('pui-thumbnail',_vm._g(_vm._b({attrs:{"title":_vm.piivoTranslate(item)},scopedSlots:_vm._u([(_vm.$scopedSlots.typeCenter)?{key:"center",fn:function(){return [_vm._t("typeCenter",null,{"item":item,"family":_vm.currentFamily})]},proxy:true}:null,(_vm.$scopedSlots.typeActions)?{key:"actions",fn:function(){return [_vm._t("typeActions",null,{"item":item,"family":_vm.currentFamily})]},proxy:true}:null,{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.piivoTranslate(item)))])]},proxy:true}],null,true)},'pui-thumbnail',Object.assign({}, {src: item.thumbnails && item.thumbnails.length ? item.thumbnails[0] : null},
            _vm.typeThumbnailProps(item, _vm.currentFamily),
            {'data-id': item.alias}),false),_vm.typeThumbnailListeners(item, _vm.currentFamily)))]}},{key:"error",fn:function(){return [_c('pui-error',{attrs:{"position":"fixed"}},[_c('i18n',{attrs:{"path":"poster.type_gallery.types.error.primary"}}),_c('i18n',{attrs:{"path":"poster.type_gallery.types.error.secondary"},scopedSlots:_vm._u([{key:"btnReload",fn:function(){return [_c('span',{staticClass:"pui-error__btn-reload",on:{"click":_vm.getFamilies}},[_vm._v(_vm._s(_vm.$t('poster.type_gallery.types.error.btnReload')))])]},proxy:true}],null,false,1182253297)})],1)]},proxy:true},{key:"noResults",fn:function(){return [_c('pui-flex',{staticClass:"poster-type-gallery__types-no-results",attrs:{"direction":"column"}},[_c('i18n',{attrs:{"path":"poster.type_gallery.types.no_types"}})],1)]},proxy:true}],null,false,2228235224)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }