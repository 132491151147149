import i18n from '../../../core/i18n';
import services from '../../../core/services';
import { memoizePromise } from '../../../utils/memoizePromise';
import importModes from '../api/importModes';
import { RootImportMode } from '../types/import';
import { IAlertsService } from './../../../platform/services/types';
import { IImportModesService } from './types';

/**
 * Import modes Service
 */
export class ImportModesService implements IImportModesService {
  /**
   * Get import configuration from a type id.
   * @param id - Type Identifier (Guid)
   * @returns the corresponding import configuration with all import modes.
   */
  getImportConfigurationByTypeId = memoizePromise(
    async (typeId: string): Promise<RootImportMode> => {
      try {
        const res = await importModes.getImportConfigurationByTypeId(typeId);
        return res.body;
      } catch (err) {
        // Alert error
        services
          .getService<IAlertsService>('alerts')
          ?.alertError(i18n.t('poster.import_modes.error') as string);

        throw err;
      }
    }
  );
}
