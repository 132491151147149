import Vue from 'vue';

export const NAMESPACE = 'actionDefinitions';

export const SET_SEND_ACTION_CONF = 'SET_SEND_ACTION_CONF';
export const CLEAR_SEND_ACTION_CONF = 'CLEAR_SEND_ACTION_CONF';

export const SET_SEND_ACTION_FORM = 'SET_SEND_ACTION_FORM';
export const CLEAR_SEND_ACTION_FORM = 'CLEAR_SEND_ACTION_FORM';

export const RESET_STATE = 'RESET_STATE';

export const ActionDefinitionsModule = {
  namespaced: true,
  state: {
    actionConfigurations: {},
    actionForms: {},
  },
  getters: {
    getActionConf: (state) => (key) => state.actionConfigurations[key],
    getActionForm: (state) => (key) => state.actionForms[key],
  },
  mutations: {
    [SET_SEND_ACTION_CONF](state, { key, conf }) {
      Vue.set(state.actionConfigurations, key, conf);
    },
    [CLEAR_SEND_ACTION_CONF](state, { key }) {
      Vue.set(state.actionConfigurations, key, null);
    },
    [SET_SEND_ACTION_FORM](state, { key, form }) {
      Vue.set(state.actionForms, key, form);
    },
    [CLEAR_SEND_ACTION_FORM](state, { key }) {
      Vue.set(state.actionForms, key, null);
    },
    [RESET_STATE](state) {
      state.actionConfigurations = {};
      state.actionForms = {};
    },
  },
  actions: {},
};
