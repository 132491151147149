<template>
  <div class="poster-validation-alert poster-validation-alert__root">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PosterValidationAlert',
};
</script>
