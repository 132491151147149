import posterDashboardCreationShortcut from './DashboardCreationShortcut';

/**
 * Array of poster dashboard widget definitions
 */
export const dashboardWidgets = [
  {
    name: 'Poster.Creation',
    widget: posterDashboardCreationShortcut,
  },
];
