import services from '../../core/services';
import { IApplicationsService } from '../services/types';
import { DashboardInfo } from '../types/dashboards';

/**
 * @param appName - the name of the app module
 * @returns the module's dashboards root alias
 */
export function getAppDashboards(appName: string): DashboardInfo[] | undefined {
  const appDetails = services
    .getService<IApplicationsService>('applications')
    ?.getAppDetails(appName);

  return appDetails?.dashboards;
}
